import { Avatar } from '@/journey/Avatar'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import TrackImpression from '@fractal/tracking/TrackImpression'
import useTranslation from 'app/i18n/client'
import Features from 'components/shared/constants/features'
import useIsFeatureEnabled from 'hooks/useIsFeatureEnabled'
import React, { useContext } from 'react'
import lowerCaseAndUnderscore from 'utils/lowerCaseAndUnderscore'

interface IAuthorBlock {
  title: string
  content: any
  name: string
  image: string
  idPerson: string
}

export interface IAuthorReviewer {
  author: IPerson
  reviewer?: IPerson
}

export type IPerson = {
  fullName: string
  shortBiography: IshortBiography
  headshot: IHeadshot
  sys: ISys
  slug: string
}

interface ISys {
  id: string
}

type IHeadshot = {
  url: string
}

type IshortBiography = {
  json: any
}

export function AuthorReviewerBlock({
  title,
  content,
  name,
  image,
  idPerson,
  position
}: IAuthorBlock & { position: number }) {
  const { trackNavigation } = useContext(AnalyticsContext)
  const href = `/people/${idPerson}`
  const unitName = 'author'
  const onClick = () => {
    trackNavigation({
      eventType: 'click',
      unitName,
      unitLocation: 'footer',
      buttonName: lowerCaseAndUnderscore(name),
      isContent: true,
      linkedContentId: idPerson,
      linkedContentUrl: href,
      positionInUnit: position
    })
  }

  // gate to hide the links
  const isFeatureEnabled = useIsFeatureEnabled(Features.PeopleLink)

  return (
    <div className='d-sm-flex flex-sm-column flex-md-row flex-sm-align-center flex-md-align-start'>
      <h2 className='lh-condensed mb-sm-3 text-center f-sm-6 d-md-none'>
        {title}
      </h2>
      <div className='flex-sm d-sm-flex flex-sm-shrink-0 o-sm-hidden mb-sm-3 mb-md-0 mr-md-4 w-sm-7 h-sm-7'>
        <Avatar
          data-testid='article-authorreviewer-image'
          src={image}
          alt=''
          sizes='84px'
        />
      </div>
      <div className='w-sm-full'>
        <h2 className='lh-condensed mb-sm-3 f-sm-6 d-sm-none d-md-block'>
          {title}
        </h2>

        {content?.content?.[0]?.content?.[0]?.value && (
          <p className='f-sm-3'>
            {isFeatureEnabled ? (
              <Link href={href} onClick={onClick}>
                <TrackImpression
                  as='span'
                  unitName={unitName}
                  unitLocation='footer'
                  buttonName={lowerCaseAndUnderscore(name)}
                  isContent
                  linkedContentId={idPerson}
                  linkedContentUrl={href}
                  positionInUnit={position}
                >
                  {name}
                </TrackImpression>
              </Link>
            ) : (
              <span>{name}</span>
            )}
            {' '}
            {content?.content[0]?.content[0]?.value}
          </p>
        )}
      </div>
    </div>
  )
}

function AuthorReviewer({ author, reviewer }: IAuthorReviewer) {
  const { t } = useTranslation()
  if (!author) return null

  return (
    <div className='pb-sm-6 mb-sm-6 bg-background-high-white'>
      <div className='container' data-testid='article-authorreviewer'>
        <div className='grid pt-sm-6'>
          <div
            className={`grid-col-sm-12 ${reviewer?.fullName
              ? 'grid-col-md-6 grid-start-lg-2 grid-col-lg-5 pr-sm-2'
              : 'grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'
              }`}
          >
            {author && (
              <AuthorReviewerBlock
                title={t('about-the-author')}
                content={author.shortBiography?.json}
                name={author.fullName.trim()}
                image={author.headshot?.url}
                idPerson={author.slug || author.sys?.id}
                position={1}
              />
            )}
          </div>

          {reviewer?.fullName && (
            <div className='grid-col-sm-12 grid-col-md-6 grid-col-lg-5 pl-md-2'>
              <AuthorReviewerBlock
                title={t('reviewed-by')}
                content={reviewer.shortBiography?.json}
                name={reviewer.fullName.trim()}
                image={reviewer.headshot?.url}
                idPerson={reviewer.slug || reviewer.sys?.id}
                position={2}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AuthorReviewer
