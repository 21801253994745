import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IArticleLink } from 'components/dynamic-page/shared/types/ArticleLink.type'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext } from 'react'
import styles from './ArticleSpotlight.module.scss'

interface IArticleSpotlightProps extends IEntry {
  content: IArticleLink
  positionInUnit: number
  spotlight?: boolean
  unitName: string
}

function ArticleSpotlight({
  content,
  spotlight,
  positionInUnit,
  unitName
}: IArticleSpotlightProps) {
  const { trackClick } = useContext(AnalyticsContext)
  const url = getContentUrl(content)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={url}
        unitName={unitName}
        componentId={unitName}
        unitLocation='inline'
        positionInUnit={positionInUnit}
        isContent
      />
      {spotlight && (
        <div
          className={`p-sm-relative rounded-lg-1 mb-sm-4 mx-sm-n5 mx-md-0 ${styles.mobileSpotlightImage}`}
        >
          <Image
            alt=''
            fill
            sizes='540px'
            className='of-sm-cover'
            src={content.thumbnail?.url || content.heroImage}
            priority
          />
        </div>
      )}

      <div
        className={`text-bold ${spotlight ? 'f-sm-6 f-lg-7' : 'f-sm-4 f-lg-5'}`}
      >
        <Link
          href={url}
          className='primary-link no-underline stretched-link lh-condensed'
          onClick={() => {
            trackClick({
              clickType: 'text',
              isContent: true,
              buttonName: null,
              linkedContentId: content.sys.id,
              linkedContentUrl: url,
              unitName,
              componentId: unitName,
              unitLocation: 'inline',
              positionInUnit,
              parentComponentId: parentComponent?.sys.id,
              positionInParentComponent: getPositionInParent(unitName)
            })
          }}
        >
          {content.title}
        </Link>
      </div>
    </>
  )
}

export default ArticleSpotlight
