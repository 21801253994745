import { gql } from '@apollo/client'
import { entryFragment, IEntry } from './Entry.type'

export interface IGenericContent extends IEntry {
  name: string
  type: string
  contents: any
}

export const genericContentFragment = gql`
  fragment genericContentFragment on GenericContent {
    ...entryFragment
    name
    type
    contents
  }
  ${entryFragment}
`

export const genericContentBySlugQuery = gql`
  query genericContentQuery(
    $preview: Boolean = false
    $locale: String = "en-US"
    $slug: String!
  ) {
    genericContentCollection(
      where: { slug: $slug }
      limit: 1
      locale: $locale
      preview: $preview
    ) {
      items {
        ...genericContentFragment
      }
    }
  }
  ${genericContentFragment}
`
