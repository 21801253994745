import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import ArrowLink from '@fractal/primitives/atoms/ArrowLink/ArrowLink'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import styles from './ComponentHomepageHeader.module.scss'
import { IComponentHomepageHeader } from './ComponentHomepageHeader.type'
import ComponentHomepageHeaderShapes from './ComponentHomepageHeaderShapes'

export function ComponentHomepageHeader({
  subtitle, title, sys, pillsCollection, cta
}: IComponentHomepageHeader) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div className='p-sm-relative o-sm-hidden bg-background-low-ochreLite z-index-1'>
      <div className={`mt-md-5 pt-sm-5 ${styles.container}`}>
        <div className='container grid grid-gap-none'>
          <div className='grid-col-sm-12 grid-col-md-10 grid-col-lg-8'>
            <TrackImpression
              unitName={sys.id}
              unitLocation='header'
              componentId={sys.id}
            />
            <h1
              id='target-skip-to-content'
              className={`f-sm-11 text-bold mb-sm-4 mb-lg-5 lh-condensed ${styles.title}`}
            >
              {title}
            </h1>
            <p className='f-sm-4 f-md-5 mb-sm-5 mb-md-6'>{subtitle}</p>
          </div>

          <div className={`grid-col-sm-12 grid-col-md-10 grid-col-lg-12 d-sm-flex flex-sm-wrap mb-sm-5 ${styles.pillsContainer}`}>
            {pillsCollection.items.map((navLink, index) => {
              const contentUrl = getContentUrl(navLink.linkTo)

              return (
                <div key={navLink.sys.id}>
                  <Link
                    href={contentUrl}
                    className='Btn Btn--primary Btn--responsive no-underline d-sm-inline-block bg-static-scarletTint f-sm-2 f-md-4'
                    onClick={() => {
                      trackClick({
                        clickType: 'button',
                        isContent: false,
                        linkText: navLink.label,
                        linkedContentUrl: contentUrl,
                        unitName: navLink.sys.id,
                        componentId: sys.id,
                        unitLocation: 'header',
                        positionInUnit: index + 1,
                        parentComponentId: parentComponent?.sys.id,
                        positionInParentComponent: getPositionInParent(sys.id)
                      })
                    }}
                  >
                    {navLink.label}
                  </Link>
                </div>
              )
            })}
          </div>
          <div className='grid-col-sm-12 f-sm-3'>
            <ArrowLink
              spanClassName='f-sm-3 text-bold'
              className='flex-sm-align-baseline'
              title={cta.label}
              url={getContentUrl(cta.linkTo)}
              onClick={() => {
                trackClick({
                  clickType: 'button',
                  isContent: false,
                  linkText: cta.label,
                  linkedContentUrl: getContentUrl(cta.linkTo),
                  unitName: sys.id,
                  componentId: sys.id,
                  unitLocation: 'header',
                  parentComponentId: parentComponent?.sys.id,
                  positionInParentComponent: getPositionInParent(sys.id)
                })
              }}

            />
          </div>
        </div>
      </div>

      <ComponentHomepageHeaderShapes />
    </div>
  )
}

export default ComponentHomepageHeader
