import { Link } from '@/journey/Link'
import SVGemail from '@/journey/Svgs/icons/email'
import SVGloading from '@/journey/Svgs/icons/loading'
import SVGprinter from '@/journey/Svgs/icons/printer'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getObjectUrlFromDownloadUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext, useRef, useState } from 'react'

const UNIT_VARIATION = 'default_content'

function EmailBtn({
  title, url, unitName, positionInUnit, id
}: any) {
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const { parent, getPositionInParent } = usePageComponentsContext()

  return (
    <a
      href={`mailto:?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(url)}`}
      aria-label='email'
      data-testid='default-email-link'
      onClick={() => {
        trackSpEngagement({
          eventType: 'share',
          platform: 'email',
          unitName,
          componentId: unitName,
          unitLocation: 'inline',
          positionInUnit,
          unitVariation: UNIT_VARIATION,
          parentComponentId: parent?.sys.id,
          positionInParentComponent: getPositionInParent(unitName),
          linkedContentId: id,
          linkedContentUrl: url
        })
      }}
    >
      <SVGemail className='vicon' width={16} height={16} />
    </a>
  )
}

function PrintBtn({
  url, unitName, positionInUnit, id
}: any) {
  const [loading, setLoading] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>()
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const [urlBlob, setUrlBlob] = useState<string>()
  const { parent, getPositionInParent } = usePageComponentsContext()

  const handlePrint = async () => {
    if (loading) return

    trackSpEngagement({
      eventType: 'share',
      platform: 'print',
      unitName,
      componentId: unitName,
      unitLocation: 'inline',
      positionInUnit,
      unitVariation: UNIT_VARIATION,
      parentComponentId: parent?.sys.id,
      positionInParentComponent: getPositionInParent(unitName),
      linkedContentId: id,
      linkedContentUrl: url
    })

    if (iframeRef.current) {
      iframeRef.current.contentWindow.print()
      return
    }

    setLoading(true)
    const objectUrl = await getObjectUrlFromDownloadUrl(url)
    setUrlBlob(objectUrl)
  }

  return (
    <button
      type='button'
      disabled={loading}
      className='Btn-link'
      onClick={handlePrint}
      aria-label='print'
      data-testid='default-print-btn'
    >
      {loading ? (
        <SVGloading
          className='vicon text-foreground-darkblue'
          width={16}
          height={16}
        />
      ) : (
        <SVGprinter className='vicon' width={16} height={16} />
      )}

      {/* using the iframe was the only way I could trigger window.print()
       if any new option is available, please replace it */}
      {urlBlob && (
        <iframe
          className='d-sm-none'
          ref={iframeRef}
          title='print'
          src={urlBlob}
          data-testid='default-print-iframe'
          onLoad={() => {
            if (!iframeRef.current) return
            iframeRef.current.contentWindow.print()
            setLoading(false)
          }}
        />
      )}
    </button>
  )
}

function DefaultContent({
  unitName, index, isExternalLink, ...content
}: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const positionInUnit = index + 1
  const contentUrl = content.url
  const { parent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`default-content-${index}`}
      className={`px-sm-4 py-sm-5 p-md-0 w-sm-full bg-background-low-white rounded-sm-1 border-shadow 
        d-sm-flex flex-md-column-reverse`}
    >
      <TrackImpression
        as='span'
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={contentUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={positionInUnit}
        unitVariation={UNIT_VARIATION}
        isContent
      />

      <div className='p-md-4 flex-sm d-sm-flex flex-sm-column flex-sm-justify-between mr-sm-4 mr-md-0'>
        <div className='lc-sm-3 p-sm-relative mb-sm-2'>
          <Link
            href={contentUrl}
            className='primary-link no-underline lh-condensed f-sm-4 f-md-5 stretched-link'
            data-testid='default-content-link'
            onClick={() => {
              trackClick({
                clickType: 'text',
                linkText: content.title,
                isContent: true,
                buttonName: null,
                linkedContentId: content.sys.id,
                linkedContentUrl: contentUrl,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                positionInUnit,
                unitVariation: UNIT_VARIATION,
                parentComponentId: parent?.sys.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {content.title}
          </Link>
        </div>

        <div className='mb-sm-4 mb-md-5 f-sm-3'>
          {content.dek}
        </div>

        <ul className='list-reset d-sm-flex'>
          {!isExternalLink
            && (
              <li className='mr-sm-5'>
                <PrintBtn
                  url={contentUrl}
                  unitName={unitName}
                  positionInUnit={positionInUnit}
                  id={content.sys.id}
                />
              </li>
            )}

          <li>
            <EmailBtn
              url={contentUrl}
              title={content.title}
              unitName={unitName}
              positionInUnit={positionInUnit}
              id={content.sys.id}
            />
          </li>
        </ul>
      </div>

    </div>
  )
}

export default DefaultContent
