import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGmicrophone from '@fractal/primitives/SVGs/icons/microphone'
import { RichText } from '@fractal/primitives/atoms/RichText'
import { Podcast } from '@fractal/primitives/molecules/Podcast'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import { IPodcastEpisodeLink } from 'components/dynamic-page/shared/types/PodcastEpisodeLink.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import useDarkMode from 'use-dark-mode'
import { ILayoutComponentDuo } from '../../LayoutComponentDuo/LayoutComponentDuo.type'

export interface IPodcastEpisodeSpotlight extends IEntry {
  title: string
  content: IPodcastEpisodeLink
  unitName: string
}

export function PodcastEpisodeSpotlight({
  title,
  content,
  isSectionLoading,
  unitName
}: IPodcastEpisodeSpotlight & { isSectionLoading?: boolean }) {
  const isDarkMode = useDarkMode()
  const { trackClick } = useContext(AnalyticsContext)
  const podcastEpisodeUrl = getContentUrl(content)
  const podcast = content.linkedFrom?.podcastCollection.items[0] || (content as any).podcast
  const podcastUrl = getContentUrl(podcast)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const layoutComponentDuoParent = parentComponent?.typename === 'LayoutComponentDuo'
    ? parentComponent as ILayoutComponentDuo : null

  return (
    <div data-testid='podcast-spotlight-section'>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={podcastEpisodeUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        unitVariation='podcast_content'
      />
      <SectionHeader
        title={title}
        level={layoutComponentDuoParent?.title ? 'h3' : 'h2'}
        isLoading={isSectionLoading}
      />
      <div className='p-sm-relative bg-background-high-ochreTint p-sm-5 rounded-sm-1 mx-sm-n3 mx-md-0'>
        <div className='p-sm-relative rounded-sm-1 o-sm-hidden mb-sm-4 no-print'>
          <div className='p-sm-relative' style={{ paddingTop: '60%' }}>
            <Image
              sizes='280px'
              className='of-sm-cover'
              src={content.thumbnail?.url}
              alt=''
              fill
            />
          </div>
        </div>
        <div className='mb-sm-3 z-index-2 p-sm-relative'>
          <Link
            href={podcastUrl}
            className={`no-underline d-sm-inline-flex flex-sm-align-center f-sm-1 pill-sm bg-static-midblue 
              text-static-creamLite text-bold px-sm-3 py-sm-1`}
            onClick={() => {
              trackClick({
                clickType: 'text',
                isContent: true,
                linkText: podcast.title,
                linkedContentId: podcast.sys.id,
                linkedContentUrl: podcastUrl,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                unitVariation: 'podcast_content',
                parentComponentId: parentComponent?.sys?.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            <SVGmicrophone
              className='vicon fill-static-creamLite mr-sm-2'
              width={14}
              height={14}
            />
            {podcast.title}
          </Link>
        </div>
        {React.createElement(
          layoutComponentDuoParent?.title ? 'h4' : 'h3',
          {
            className: 'f-sm-6 f-md-7 lh-condensed text-bold mb-md-4 mb-sm-2'
          },
          <Link
            href={podcastEpisodeUrl}
            className='stretched-link primary-link no-underline'
            onClick={() => {
              trackClick({
                clickType: 'text',
                isContent: true,
                linkText: content.title,
                linkedContentId: content.sys.id,
                linkedContentUrl: podcastEpisodeUrl,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                unitVariation: 'podcast_content',
                parentComponentId: parentComponent?.sys?.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {content.title}
          </Link>
        )}
        <div className='f-sm-4 mb-sm-5'>
          <RichText richTextContent={content.description.json} richTextLinks={content.description.links} unstyled />
        </div>

        <div className='mb-sm-n4 mx-sm-n4 z-index-2 p-sm-relative no-print'>
          <Podcast
            key={content.podcastId}
            audioId={content.podcastId}
            darkMode={isDarkMode.value}
            type='mini'
          />
        </div>
      </div>
    </div>
  )
}
