import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import { ILayoutComponentDuo } from '../../LayoutComponentDuo/LayoutComponentDuo.type'
import styles from './ArticleSpotlight.module.scss'
import { IArticleSpotlight } from './ArticleSpotlight.type'

export function ArticleSpotlight({
  title,
  content,
  isSectionLoading,
  unitName
}: IArticleSpotlight & { isSectionLoading?: boolean }) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const layoutComponentDuoParent = parentComponent?.typename === 'LayoutComponentDuo'
    ? parentComponent as ILayoutComponentDuo : null
  const url = getContentUrl(content)
  let preview = ''

  const articleContent = content.articleContent?.json

  if (articleContent) {
    const toPlainText = documentToPlainTextString(articleContent)
    preview = toPlainText.substring(0, 400)
  }

  return (
    <div data-testid='video-spotlight-section'>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={url}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        unitVariation='video_content'
        isContent
        linkText={content.title}
      />
      <SectionHeader
        title={title}
        level={layoutComponentDuoParent?.title ? 'h3' : 'h2'}
        isLoading={isSectionLoading}
      />
      <div className='p-sm-relative bg-background-high-ochreTint p-sm-5 rounded-sm-1 mx-sm-n3 mx-md-0'>
        <div className='p-sm-relative rounded-sm-1 o-sm-hidden mb-sm-4 no-print'>
          <div className='p-sm-relative' style={{ paddingTop: '60%' }}>
            <Image
              sizes='280px'
              className='of-sm-cover'
              src={content.thumbnail?.url}
              alt=''
              fill
            />
          </div>
        </div>
        {React.createElement(
          layoutComponentDuoParent?.title ? 'h4' : 'h3',
          {
            className: 'f-sm-6 f-md-7 lh-condensed text-bold mb-sm-2 mb-md-4'
          },
          <Link
            href={url}
            className='primary-link no-underline stretched-link lh-condensed'
            onClick={() => {
              trackClick({
                clickType: 'text',
                isContent: true,
                linkText: content.title,
                linkedContentId: content.sys.id,
                linkedContentUrl: url,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                positionInUnit: 1,
                parentComponentId: parentComponent?.sys?.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {content.title}
          </Link>
        )}
        {preview && (
          <p className={`f-sm-4 lc-sm-4 text-truncate ${styles.truncate}`}>
            {preview}
          </p>
        )}
      </div>
    </div>
  )
}

export default ArticleSpotlight
