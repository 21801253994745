import { Link } from '@/journey/Link'
import SVGarrowLeft from '@fractal/primitives/SVGs/icons/arrowLeft'
import SVGarrowRight from '@fractal/primitives/SVGs/icons/arrowRight'
import SVGcaretLeft from '@fractal/primitives/SVGs/icons/caretLeft'
import SVGcaretRight from '@fractal/primitives/SVGs/icons/caretRight'
import React from 'react'
import { useMedia } from 'the-platform'
import { StyleArrowLink, StyleIconSVG } from './styled'
import { IArrowLink } from './types'

export default function ArrowLink({
  textColor,
  textsize,
  iconAlign,
  iconType,
  onItemClick,
  mdSize = 20,
  smSize = 20,
  className = 'no-underline',
  spanClassName,
  ...props
}: IArrowLink) {
  const mediumBP = useMedia({ minWidth: 768 })
  const size = mediumBP ? mdSize : smSize

  const component = props.url ? Link : 'button'

  return (
    <StyleArrowLink
      as={component}
      onClick={onItemClick}
      textcolor={textColor}
      textsize={textsize}
      iconAlign={iconAlign}
      className={`${className} Btn-link`}
      href={props.url}
      {...props}
    >
      {iconAlign === 'left' && (
        <StyleIconSVG>
          {iconType === 'caret' ? (
            <SVGcaretLeft className='fill-svg' width={size} height={size} />
          ) : (
            <SVGarrowLeft className='fill-svg' width={size} height={size} />
          )}
        </StyleIconSVG>
      )}
      <span className={spanClassName}>{props.title}</span>
      {iconAlign === 'right' && (
        <StyleIconSVG>
          {iconType === 'caret' ? (
            <SVGcaretRight className='fill-svg' width={size} height={size} />
          ) : (
            <SVGarrowRight className='fill-svg' width={size} height={size} />
          )}
        </StyleIconSVG>
      )}{' '}
    </StyleArrowLink>
  )
}

ArrowLink.defaultProps = {
  textColor: 'default',
  textsize: 'default',
  iconAlign: 'right',
  iconType: 'arrow'
}
