import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import { IComponentHomepageSectionBlock } from './ComponentHomepageSectionBlock.type'

export function ComponentHomepageSectionBlock({
  sys, title, textContent, cta, content
}: IComponentHomepageSectionBlock) {
  const { trackClick } = useContext(AnalyticsContext)
  const url = 'url' in cta ? cta?.url : getContentUrl(cta.linkTo)

  return (
    <div className={`container ${content ? 'mt-sm-5 mb-sm-6 mt-md-6' : 'mt-sm-6 mb-sm-6 mt-md-8 mb-lg-7'}`}>
      <TrackImpression
        componentId={sys.id}
        unitName={sys.id}
        unitLocation='inline'
      />
      <div className='grid flex-lg-align-center'>
        {content && (
          <div className='grid-col-sm-12 grid-start-md-3 grid-col-md-8 grid-col-lg-5 mr-lg-n5 mb-sm-3 mb-md-4 mb-lg-0'>
            <Image
              src={content}
              width={400}
              height={400}
              alt=''
              sizes='100vw'
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        )}
        <div className={`grid-col-sm-12 grid-start-md-2 grid-col-md-10
          ${content ? 'grid-start-lg-7 grid-col-lg-5' : 'grid-start-lg-4 grid-col-lg-6'}`}
        >
          {title && <h3 className='f-sm-8 f-lg-10 mb-sm-5 text-lg-center'>{title}</h3>}
          <p className='f-sm-3 f-md-5'>
            {textContent}
          </p>
          <div className={`d-sm-flex ${content ? '' : 'flex-lg-justify-center'}`}>
            <Link
              href={url}
              className='Btn Btn--outline Btn--responsive mt-sm-4 mt-md-6 no-underline'
              onClick={() => trackClick({
                clickType: 'button',
                componentId: sys.id,
                unitName: sys.id,
                unitLocation: 'inline',
                linkedContentUrl: url,
                linkText: cta.label
              })}
            >
              {cta.label}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComponentHomepageSectionBlock
