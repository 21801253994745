import Footer from '../shared/components/Footer/Footer.client'
import TopNav from '../shared/components/TopNav/TopNav.client'
import ComponentAtAGlance from './contentfulComponents/ComponentAtAGlance/ComponentAtAGlance'
import ComponentAuthorCard from './contentfulComponents/ComponentAuthorCard/ComponentAuthorCard'
import ComponentBioHeader from './contentfulComponents/ComponentBioHeader/ComponentBioHeader'
import ComponentBodyText from './contentfulComponents/ComponentBodyText/ComponentBodyText'
import ComponentCampaignHeader from './contentfulComponents/ComponentCampaignHeader/ComponentCampaignHeader'
import ComponentCardCarousel from './contentfulComponents/ComponentCardCarousel/ComponentCardCarousel'
import ComponentCardList from './contentfulComponents/ComponentCardList/ComponentCardList'
import ComponentContentFilter from './contentfulComponents/ComponentContentFilter/ComponentContentFilter'
import ComponentCtaButton from './contentfulComponents/ComponentCtaButton/ComponentCtaButton'
import ComponentEssentials from './contentfulComponents/ComponentEssentials/ComponentEssentials'
import ComponentFilteredResources from './contentfulComponents/ComponentFilteredResources/ComponentFilteredResources'
import ComponentGenericContent from './contentfulComponents/ComponentGenericContent/ComponentGenericContent'
import ComponentGenericHeader from './contentfulComponents/ComponentGenericHeader/ComponentGenericHeader'
import ComponentHeroHeader from './contentfulComponents/ComponentHeroHeader/ComponentHeroHeader'
import ComponentHomepageFeaturedSection from './contentfulComponents/ComponentHomepageFeaturedSection/ComponentHomepageFeaturedSection'
import ComponentHomepageHeader from './contentfulComponents/ComponentHomepageHeader/ComponentHomepageHeader'
import ComponentHomepageImpact from './contentfulComponents/ComponentHomepageImpact/ComponentHomepageImpact'
import ComponentHomepageSectionBlock from './contentfulComponents/ComponentHomepageSectionBlock/ComponentHomepageSectionBlock'
import ComponentHomepageTestimonial from './contentfulComponents/ComponentHomepageTestimonial/ComponentHomepageTestimonial'
import ComponentHomepageVideo from './contentfulComponents/ComponentHomepageVideo/ComponentHomepageVideo'
import ComponentLargeHero from './contentfulComponents/ComponentLargeHero/ComponentLargeHero'
import ComponentMoreAboutUs from './contentfulComponents/ComponentMoreAboutUs/ComponentMoreAboutUs'
import ComponentNewsletter from './contentfulComponents/ComponentNewsletter/ComponentNewsletter'
import ComponentPromoBanner from './contentfulComponents/ComponentPromoBanner/ComponentPromoBanner'
import ComponentResourceList from './contentfulComponents/ComponentResourceList/ComponentResourceList'
import ComponentSocial from './contentfulComponents/ComponentSocial/ComponentSocial'
import ComponentSpotlight from './contentfulComponents/ComponentSpotlight/ComponentSpotlight'
import ComponentTeaserList from './contentfulComponents/ComponentTeaserList/ComponentTeaserList'
import ComponentWunderBanner from './contentfulComponents/ComponentWunderBanner/ComponentWunderBanner'
import ComponentWunderExpert from './contentfulComponents/ComponentWunderExpert/ComponentWunderExpert'
import LayoutComponentDuo from './contentfulComponents/LayoutComponentDuo/LayoutComponentDuo'
import LayoutComponentSection from './contentfulComponents/LayoutComponentSection/LayoutComponentSection'

export default {
  ComponentEssentials: {
    component: ComponentEssentials
  },
  ComponentWunderBanner: {
    component: ComponentWunderBanner
  },
  ComponentCardList: {
    component: ComponentCardList
  },
  LayoutComponentDuo: {
    component: LayoutComponentDuo
  },
  LayoutComponentSection: {
    component: LayoutComponentSection
  },
  ComponentTeaserList: {
    component: ComponentTeaserList
  },
  ComponentPromoBanner: {
    component: ComponentPromoBanner
  },
  ComponentCardCarousel: {
    component: ComponentCardCarousel
  },
  ComponentFilteredResources: {
    component: ComponentFilteredResources
  },
  ComponentHeroHeader: {
    component: ComponentHeroHeader
  },
  ComponentResourceList: {
    component: ComponentResourceList
  },
  ComponentCtaButton: {
    component: ComponentCtaButton
  },
  ComponentContentFilter: {
    component: ComponentContentFilter
  },
  ComponentNewsletter: {
    component: ComponentNewsletter
  },
  ComponentSpotlight: {
    component: ComponentSpotlight
  },
  ComponentCampaignHeader: {
    component: ComponentCampaignHeader
  },
  ComponentLargeHero: {
    component: ComponentLargeHero
  },
  ComponentBodyText: {
    component: ComponentBodyText
  },
  ComponentAuthorCard: {
    component: ComponentAuthorCard
  },
  ComponentGenericHeader: {
    component: ComponentGenericHeader
  },
  ComponentAtAGlance: {
    component: ComponentAtAGlance
  },
  ComponentBioHeader: {
    component: ComponentBioHeader
  },
  GenericContent: {
    component: ComponentGenericContent
  },
  ComponentSocial: {
    component: ComponentSocial
  },
  ComponentMoreAboutUs: {
    component: ComponentMoreAboutUs
  },
  ComponentWunderExpert: {
    component: ComponentWunderExpert
  },
  ComponentHomepageImpact: {
    component: ComponentHomepageImpact
  },
  ComponentHomepageSectionBlock: {
    component: ComponentHomepageSectionBlock
  },
  ComponentHomepageHeader: {
    component: ComponentHomepageHeader
  },
  ComponentHomepageVideo: {
    component: ComponentHomepageVideo
  },
  ComponentHomepageTestimonial: {
    component: ComponentHomepageTestimonial
  },
  ComponentFooter: {
    component: Footer
  },
  ComponentTopNav: {
    component: TopNav
  },
  ComponentHomepageFeaturedSection: {
    component: ComponentHomepageFeaturedSection
  }
}
