import DynamicComponentRenderer from 'components/dynamic-page/DynamicComponentRenderer'
import { PageComponentsProvider } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext, getLocalStorageData } from 'components/dynamic-page/providers/PageContext'
import Filter from 'components/dynamic-page/shared/components/Filter'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentFilteredResources } from './ComponentFilteredResources.type'

function ComponentFilteredResources(
  props: IComponentFilteredResources
) {
  const router = useRouter()
  const { contentFilter, setContentFilter } = useContext(PageContext)
  const {
    sys, title, everyoneCollection, kidsCollection, teensCollection
  } = props
  let collection = everyoneCollection.items
  if (contentFilter === 'kids') {
    collection = kidsCollection.items
  } else if (contentFilter === 'teens') {
    collection = teensCollection.items
  }

  const onFilterChange = (filter) => {
    setContentFilter(filter)
    const searchParams = new URLSearchParams(window.location.search)
    if (filter) searchParams.set('filter', filter)
    else searchParams.delete('filter')
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, ...Object.fromEntries(searchParams) }
      },
      undefined,
      { scroll: false, shallow: true }
    )
  }

  useEffect(() => {
    const localStorageData = getLocalStorageData()
    onFilterChange(
      (router.query.filter as string) || localStorageData.filter || ''
    )
  }, [])

  return (
    <div className={sharedStyles.sectionMargin}>
      <TrackImpression
        unitName={sys.id}
        componentId={sys.id}
        unitLocation='inline'
        childComponentId={collection.map((c) => c.sys.id)}
      />
      <PageComponentsProvider parent={props} entries={collection}>
        <div className='grid grid-gap-none'>
          <div className='grid-col-sm-12 grid-col-md-6'>
            <SectionHeader title={title} />
          </div>
          <div
            className={`grid-col-sm-12 grid-col-md-6 grid-col-lg-4 d-sm-flex 
            flex-sm-align-center flex-md-justify-end mb-sm-4`}
          >
            <Filter unitName={sys.id} onChange={onFilterChange} contentFilter={contentFilter} />
          </div>
        </div>
        {collection.map((b) => (
          <div className='my-sm-4 mb-md-5 mb-lg-6' key={b.type}>
            <DynamicComponentRenderer block={b} parent={props} />
          </div>
        ))}
      </PageComponentsProvider>
    </div>
  )
}

export default ComponentFilteredResources
