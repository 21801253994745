import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { useContext } from 'react'
import { IContentfulCta } from '../../types/ContentfulCta.type'
import ContentfulIcon from '../ContentfulIcon'
import { TrackImpression } from '../TrackImpression'

interface ISnowplowEvents {
  unitName: string
}

function ContentfulCta({
  ctaLink,
  ctaIcon,
  ctaLabel,
  snowplowEvents,
  ...props
}: IContentfulCta & { snowplowEvents: ISnowplowEvents }) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  if (!ctaLink || !ctaLabel) return null
  const handleClick = () => {
    trackClick({
      clickType: 'button',
      buttonText: ctaLabel,
      buttonName: 'cta',
      linkedContentUrl: ctaLink,
      unitName: snowplowEvents.unitName,
      unitLocation: 'inline',
      componentId: snowplowEvents.unitName,
      parentComponentId: parentComponent?.sys.id,
      positionInParentComponent: getPositionInParent(snowplowEvents.unitName)
    })
  }
  return (
    <>
      <TrackImpression
        unitName={snowplowEvents.unitName}
        unitLocation='inline'
        buttonText={ctaLabel}
        linkedContentUrl={ctaLink}
        componentId={snowplowEvents.unitName}
        buttonName='cta'
      />
      <a
        href={ctaLink}
        onClick={handleClick}
        className='Btn Btn--primary no-underline'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {ctaIcon && (
          <ContentfulIcon name={ctaIcon} className='fill-svg mr-sm-2' />
        )}
        {ctaLabel}
      </a>
    </>
  )
}

export default ContentfulCta
