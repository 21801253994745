import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext, useEffect } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { ArticleSpotlight } from './ArticleSpotlight'
import styles from './ComponentEssentials.module.scss'
import { IComponentEssentials } from './ComponentEssentials.type'
import { VideoSpotlight } from './VideoSpotlight'
import PodcastEpisodeSpotlight from './PodcastEpisodeSpotlight'

const componentMap = {
  Article: ArticleSpotlight,
  VideoBlock: VideoSpotlight,
  PodcastEpisode: PodcastEpisodeSpotlight
}

export default function ComponentEssentials({
  sys,
  title,
  spotlight,
  contentLimit,
  contentType,
  filterByCollection,
  contentListCollection,
  externalFilter
}: IComponentEssentials) {
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)

  const handpickedContentList = {
    items: [spotlight, ...contentListCollection.items].filter(
      (content) => !!content
    )
  }

  const resourceList = getData(sys.id).slice(0, 5)

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit,
      contentType,
      filterByCollection,
      contentListCollection: handpickedContentList,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [
    sys.id,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  ])

  return (
    <div
      data-testid='essentials-section'
      className={sharedStyles.sectionMargin}
    >
      <TrackImpression
        unitName={sys.id}
        componentId={sys.id}
        unitLocation='inline'
      />
      <div>
        <SectionHeader title={title} isLoading={isSectionLoading(sys.id)} />
        <div>
          <ul className={`list-reset m-sm-0 ${styles.gridTemplate}`}>
            {resourceList.map((content, index) => {
              const isFirst = index === 0
              const isLast = index === resourceList.length - 1

              const liClasses = `mb-sm-5 pb-sm-5 border-sm-bottom-1 border-static-ochreTint
                ${!isFirst && !isLast ? '' : 'mb-md-0 pb-md-0 border-md-bottom-0'
              }`

              const Component = componentMap[content.typename]
              if (!Component) return null

              return (
                <li
                  key={content.sys.id}
                  style={{ gridArea: `article_${index + 1}` }}
                  className={`d-md-flex flex-md-column p-sm-relative ${liClasses}`}
                >
                  <Component
                    content={content}
                    spotlight={isFirst}
                    positionInUnit={index + 1}
                    unitName={sys.id}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
