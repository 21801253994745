import { IComponentLargeHero } from './ComponentLargeHero.type'
import LargeImage from './LargeImage'
import LargeVideo from './LargeVideo'

export default function ComponentLargeHero({ image, video, ...props }: IComponentLargeHero) {
  if (!image?.url && !video?.videoUrl) return null
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (image?.url) return <LargeImage content={image} {...props} />
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (video?.videoUrl) return <LargeVideo content={video} {...props} />
}
