import { AnalyticsContext } from '@context/AnalyticsContext'
import useTranslation from 'app/i18n/client'
import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import StyledButton from 'components/dynamic-page/shared/components/StyledButton'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IArticleLink } from 'components/dynamic-page/shared/types/ArticleLink.type'
import { IDownloadableAssetLink } from 'components/dynamic-page/shared/types/DownloadableAssetLink.type'
import { useContext, useEffect, useState } from 'react'
import { ILayoutComponentDuo } from '../LayoutComponentDuo/LayoutComponentDuo.type'
import ArticleItem from './ArticleItem'
import styles from './ComponentTeaserList.module.scss'
import { IComponentTeaserList } from './ComponentTeaserList.type'
import DownloadItem from './DownloadItem'

const initialLimit = 4

export default function ComponentTeaserList({
  sys,
  title,
  emailTags,
  contentLimit,
  contentType,
  filterByCollection,
  contentListCollection,
  externalFilter
}: IComponentTeaserList) {
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)
  const { trackClick } = useContext(AnalyticsContext)
  const [limit, setLimit] = useState(initialLimit)
  const { t } = useTranslation()
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const layoutComponentDuoParent = parentComponent?.typename === 'LayoutComponentDuo'
    ? parentComponent as ILayoutComponentDuo : null

  const resourceList = getData(sys.id)

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit,
      contentType,
      filterByCollection,
      contentListCollection,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [
    sys.id,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  ])

  const handleLoadMore = () => {
    trackClick({
      clickType: 'button',
      buttonName: 'load_more',
      buttonText: t('explore-more'),
      unitName: sys.id,
      componentId: sys.id,
      unitLocation: 'inline',
      parentComponentId: parentComponent?.sys?.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })

    const newLimit = limit + initialLimit
    setLimit(newLimit)
  }

  return (
    <>
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
      />
      <div
        data-testid='download-section'
        className={`p-sm-relative h-sm-full d-sm-flex flex-sm-column ${limit > initialLimit ? styles.downloadContainer : ''
          }`}
      >
        <SectionHeader
          title={title}
          level={layoutComponentDuoParent?.title ? 'h3' : 'h2'}
          isLoading={isSectionLoading(sys.id)}
        />
        <div className={`h-sm-full d-sm-flex flex-sm-column px-sm-5 py-sm-5 py-lg-6 rounded-sm-1 border-shadow 
          bg-background-high-white mx-sm-n3 mx-md-0 o-sm-auto`}
        >
          <ul className='list-reset m-sm-0 flex-sm'>
            {resourceList.map((content, index) => {
              if (index > limit - 1) return null

              const isLast = index === limit - 1 || index === resourceList.length - 1

              return (
                <li
                  key={content.sys.id}
                  className={`${isLast
                    ? ''
                    : 'pb-sm-5 mb-sm-5 border-sm-bottom border-foreground-grey3'
                    }`}
                >
                  <div className='d-sm-flex p-sm-relative'>
                    {content.typename === 'DownloadableAsset' ? (
                      <DownloadItem
                        content={content as IDownloadableAssetLink}
                        positionInUnit={index + 1}
                        unitName={sys.id}
                        emailTags={emailTags}
                      />
                    ) : (
                      <ArticleItem
                        article={content as IArticleLink}
                        positionInUnit={index + 1}
                        unitName={sys.id}
                      />
                    )}
                  </div>
                </li>
              )
            })}
          </ul>

          {limit < resourceList.length && (
            <div className='mt-sm-5 text-center no-print'>
              <StyledButton onClick={handleLoadMore}>
                <TrackImpression
                  unitName={sys.id}
                  unitLocation='inline'
                  componentId={sys.id}
                  buttonName='load_more'
                  buttonText={t('explore-more')}
                />
                {t('explore-more')}
              </StyledButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
