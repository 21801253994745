import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import SVGemail from '@/journey/Svgs/icons/email'
import SVGloading from '@/journey/Svgs/icons/loading'
import SVGprinter from '@/journey/Svgs/icons/printer'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext, useRef, useState } from 'react'
import styles from './ArticleResourceContent.module.scss'

// eslint-disable-next-line max-len
const FALLBACK_IMG = 'https://images.ctfassets.net/p0qf7j048i0q/hJEgpmjWW4RhB03AdHmpq/8e08ba4df94c7f59bb2fa91104ca9815/empty_article.svg'
const UNIT_VARIATION = 'article_content'

function PrintBtn({
  url, unitName, positionInUnit, id
}: any) {
  const [loading, setLoading] = useState(false)
  const [loadIframe, setLoadIframe] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>()
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const { parent, getPositionInParent } = usePageComponentsContext()

  const handlePrint = () => {
    if (loading) return

    trackSpEngagement({
      eventType: 'share',
      platform: 'print',
      unitName,
      componentId: unitName,
      unitLocation: 'inline',
      positionInUnit,
      unitVariation: UNIT_VARIATION,
      parentComponentId: parent?.sys.id,
      positionInParentComponent: getPositionInParent(unitName),
      linkedContentId: id,
      linkedContentUrl: url
    })

    if (iframeRef.current) {
      iframeRef.current.contentWindow.print()
    }

    if (!iframeRef.current) {
      setLoadIframe(true)
      setLoading(true)
    }
  }

  return (
    <button
      disabled={loading}
      className='Btn-link'
      onClick={handlePrint}
      aria-label='print'
      type='button'
      data-testid='resource-center-print-btn'
    >
      {loading ? (
        <SVGloading
          className='vicon text-foreground-darkblue'
          width={16}
          height={16}
        />
      ) : (
        <SVGprinter className='vicon' width={16} height={16} />
      )}

      {/* using the iframe was the only way I could trigger window.print()
       if any new option is available, please replace it */}
      {loadIframe && (
        <iframe
          className='d-sm-none'
          ref={iframeRef}
          title='print'
          src={`${process.env.NEXT_PUBLIC_APP_DOMAIN + url}?gate=none`}
          data-testid='resource-center-print-iframe'
          onLoad={() => {
            if (!iframeRef.current) return

            iframeRef.current.contentWindow.print()
            setLoading(false)
          }}
        />
      )}
    </button>
  )
}

function EmailBtn({
  title, url, unitName, positionInUnit, id
}: any) {
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const { parent, getPositionInParent } = usePageComponentsContext()

  return (
    <a
      href={`mailto:?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(`${process.env.NEXT_PUBLIC_APP_DOMAIN + url}`)}`}
      aria-label='email'
      data-testid='resource-center-email-link'
      onClick={() => {
        trackSpEngagement({
          eventType: 'share',
          platform: 'email',
          unitName,
          unitLocation: 'inline',
          positionInUnit,
          unitVariation: UNIT_VARIATION,
          componentId: unitName,
          parentComponentId: parent?.sys.id,
          positionInParentComponent: getPositionInParent(unitName),
          linkedContentId: id,
          linkedContentUrl: url
        })
      }}
    >
      <SVGemail className='vicon' width={16} height={16} />
    </a>
  )
}

function ArticleResourceContent({ unitName, index, ...article }: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const positionInUnit = index + 1
  const articleUrl = getContentUrl(article)
  const { parent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`article-resource-content-${index}`}
      className={`px-sm-4 py-sm-5 p-md-0 w-sm-full bg-background-low-white rounded-sm-1
        border-shadow d-sm-flex flex-md-column-reverse`}
    >
      <TrackImpression
        as='span'
        key={article.sys.id}
        linkedContentId={article.sys.id}
        linkedContentUrl={articleUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={positionInUnit}
        unitVariation={UNIT_VARIATION}
        isContent
        linkText={article.title}
      />

      <div className='p-md-4 flex-sm d-sm-flex flex-sm-column flex-sm-justify-between mr-sm-4 mr-md-0'>
        <div className='lc-sm-3 p-sm-relative mb-sm-4 mb-md-5'>
          <Link
            href={`${articleUrl}?from=app`}
            className='primary-link no-underline lh-condensed f-sm-4 f-md-5 stretched-link'
            data-testid='article-resource-content-link'
            onClick={() => {
              trackClick({
                clickType: 'text',
                isContent: true,
                linkText: article.title,
                linkedContentId: article.sys.id,
                linkedContentUrl: articleUrl,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                positionInUnit,
                unitVariation: UNIT_VARIATION,
                parentComponentId: parent?.sys.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {article.title}
          </Link>
        </div>

        <ul className='list-reset d-sm-flex'>
          <li className='mr-sm-5'>
            <PrintBtn
              url={articleUrl}
              unitName={unitName}
              positionInUnit={positionInUnit}
              id={article.sys.id}
            />
          </li>

          <li>
            <EmailBtn
              url={articleUrl}
              title={article.title}
              unitName={unitName}
              positionInUnit={positionInUnit}
              id={article.sys.id}
            />
          </li>
        </ul>
      </div>

      <div
        className={`${styles.imageWrapper} p-sm-relative rounded-sm-1 o-sm-hidden no-print`}
      >
        <Image
          sizes='(max-width: 767px) 120px, 300px'
          className='of-sm-cover'
          fill
          src={
            article.thumbnail?.url || article.heroImage || FALLBACK_IMG
          }
          alt=''
        />
      </div>
    </div>
  )
}

export default ArticleResourceContent
