import { RichText } from '@fractal/primitives/atoms/RichText'
import VideoPlayer from 'components/articles/VideoPlayer'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import { IVideoBlockLink } from 'components/dynamic-page/shared/types/VideoBlockLink.type'
import React from 'react'
import { ILayoutComponentDuo } from '../../LayoutComponentDuo/LayoutComponentDuo.type'

export interface IVideoSpotlight extends IEntry {
  title: string
  content: IVideoBlockLink
  unitName: string
}

export function VideoSpotlight({
  title,
  content,
  isSectionLoading,
  unitName
}: IVideoSpotlight & { isSectionLoading?: boolean }) {
  const { parent: parentComponent } = usePageComponentsContext()
  const layoutComponentDuoParent = parentComponent?.typename === 'LayoutComponentDuo'
    ? parentComponent as ILayoutComponentDuo : null
  if (!content?.videoUrl) return null

  return (
    <div data-testid='video-spotlight-section'>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={content.videoUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        unitVariation='video_content'
      />
      <SectionHeader
        title={title}
        level={layoutComponentDuoParent?.title ? 'h3' : 'h2'}
        isLoading={isSectionLoading}
      />
      <div className='p-sm-relative bg-background-high-ochreTint p-sm-5 rounded-sm-1 mx-sm-n3 mx-md-0'>
        <div className='p-sm-relative rounded-sm-1 o-sm-hidden mb-sm-4 no-print'>
          <div className='p-sm-relative'>
            <VideoPlayer
              url={content.videoUrl}
              videoId={content.sys.id}
              fullSizeContainer
              applyAspectRatio
            />
          </div>
        </div>
        {React.createElement(layoutComponentDuoParent?.title ? 'h4' : 'h3', {
          className: 'f-sm-6 f-md-7 lh-condensed text-bold mb-sm-2 mb-md-4'
        }, content.title || content.internalTitle)}
        {content.description?.json && (
          <div className='f-sm-4'>
            <RichText richTextContent={content.description.json} richTextLinks={content.description.json} unstyled />
          </div>
        )}
      </div>
    </div>
  )
}
