import { AuthorReviewerBlock } from 'components/articles/AuthorReviewer/AuthorReviewer'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentAuthorCard } from './ComponentAuthorCard.type'

function ComponentAuthorCard({
  title,
  author,
  sys
}: IComponentAuthorCard) {
  const { pageLayout } = useContext(PageContext)
  if (!author) return null

  return (
    <div
      className={
        pageLayout.columnWidthLarge === 10
          ? sharedStyles.fullWidthLargeGrid10
          : sharedStyles.fullWidthLargeGrid12
      }
    >
      <div
        className='pb-sm-6 bg-background-high-white'
        data-testid='author-card-section'
      >
        <TrackImpression
          unitName={sys.id}
          unitLocation='footer'
          componentId={sys.id}
        />
        <div className='container'>
          <div className='grid pt-sm-6'>
            <div className='grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'>
              <AuthorReviewerBlock
                title={title}
                content={author.shortBiography?.json}
                name={author.fullName.trim()}
                image={author.headshot?.url}
                idPerson={author.sys?.id}
                position={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComponentAuthorCard
