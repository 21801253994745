import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import SVGdropdown from '@fractal/primitives/SVGs/icons/dropdown'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { IExternalTag } from 'components/dynamic-page/shared/types/ExternalTag.type'
import { useContext, useState } from 'react'
import ReactModal from 'react-modal'
import { useMedia } from 'the-platform'
import styles from './FilterButton.module.scss'

const customStylesMd = {
  content: {
    padding: '52px',
    borderRadius: '4px',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-white)',
    width: 600,
    height: 'fit-content',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 100 }
}

const customStylesSm = {
  content: {
    inset: '0',
    padding: '52px 30px',
    borderRadius: '0',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-white)',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: { zIndex: 100 }
}

function FilterButton({
  filterGroup, onFilterChange, savedFilters
}: any) {
  const { t } = useTranslation()
  const isMd = useMedia({ minWidth: 768 })
  const { trackSpEngagement, trackClick } = useContext(AnalyticsContext)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [filters, setFilters] = useState(savedFilters)
  const { parent, getPositionInParent } = usePageComponentsContext()

  const handleCheck = (event, tag) => {
    let updatedList = [...filters]
    if (event.target.checked) {
      updatedList = [...filters, tag]
    } else {
      updatedList.splice(
        filters.findIndex((a) => a.sys.id === tag.sys.id),
        1
      )
    }
    setFilters(updatedList)
  }

  const getButtonTitle = () => {
    if (!savedFilters.length) return filterGroup.title
    const firstFilter = savedFilters[0]

    return `${firstFilter.title || firstFilter.name} ${savedFilters.length > 1 ? `, +${savedFilters.length - 1}` : ''
    }`
  }

  const saveFilters = () => {
    onFilterChange(filters)
    trackSpEngagement({
      eventType: 'select',
      unitName: filterGroup.sys.id,
      componentId: filterGroup.sys.id,
      unitLocation: 'modal',
      submittedList: `[${filters.map((filter) => filter.sys.id).toString()}]`,
      parentComponentId: parent.sys.id,
      positionInParentComponent: getPositionInParent(filterGroup.sys.id)
    })

    setIsModalOpened(false)
  }

  const closeModal = () => {
    setFilters(savedFilters)
    trackSpEngagement({
      eventType: 'dismiss',
      unitName: filterGroup.sys.id,
      componentId: filterGroup.sys.id,
      unitLocation: 'modal',
      parentComponentId: parent.sys.id,
      positionInParentComponent: getPositionInParent(filterGroup.sys.id)
    })

    setIsModalOpened(false)
  }

  return (
    <div
      className='mb-sm-3 mb-md-0 mr-md-4'
      data-testid='content-filter-group-button'
    >
      <button
        type='button'
        data-testid='filter-group-modal-button'
        onClick={() => {
          setIsModalOpened(true)
          trackClick({
            clickType: 'button',
            unitName: filterGroup.sys.id,
            componentId: filterGroup.sys.id,
            buttonName: 'filter',
            unitLocation: 'inline',
            parentComponentId: parent.sys.id,
            positionInParentComponent: getPositionInParent(filterGroup.sys.id),
            isModal: true
          })
        }}
        className={`Btn-link border-shadow f-sm-2 py-sm-3 px-sm-4 pill-sm bg-background-high-white 
          d-sm-flex flex-sm-align-center flex-sm-justify-between w-sm-full w-md-auto`}
      >
        <span className='text-foreground-midblueShade f-sm-2 text-bold mr-sm-4'>
          {getButtonTitle()}
        </span>
        <SVGdropdown className='vicon' width={16} height={16} />
      </button>
      <ReactModal
        isOpen={isModalOpened}
        onRequestClose={() => closeModal()}
        style={isMd ? customStylesMd : customStylesSm}
        preventScroll
        testId='filter-group-modal'
      >
        <button
          aria-label={t('close-wunder-window')}
          data-testid='close-modal'
          onClick={closeModal}
          type='button'
          className='Btn-link p-sm-absolute right-sm-4 top-sm-4 right-md-5 top-md-5'
        >
          <SVGclose className='vicon' />
        </button>

        <h1 className='f-sm-6 f-md-5 mb-sm-5 lh-condensed'>
          {filterGroup.title}
        </h1>

        <div
          className={`${styles.tagContainer} d-sm-flex flex-sm-wrap o-sm-auto p-sm-1`}
        >
          {filterGroup.tagsCollection.items.map((tag: IExternalTag) => {
            const isChecked = filters.findIndex((filter) => filter.sys.id === tag.sys.id) > -1

            return (
              <label
                key={tag.sys.id}
                htmlFor={tag.sys.id}
                className={`f-sm-3 text-bold px-sm-3 py-sm-2 border-sm-2 rounded-sm-1 p-sm-relative ${styles.label
                } ${isChecked
                  ? 'border-background-high-scarletTint bg-background-high-scarletTint'
                  : 'border-background-high-peach bg-background-low-peachTint'
                }`}
              >
                <input
                  value={tag.sys.id}
                  id={tag.sys.id}
                  type='checkbox'
                  checked={isChecked}
                  onChange={(e) => handleCheck(e, tag)}
                  className={`${styles.input} p-sm-absolute w-sm-full h-sm-full top-sm-0 left-sm-0 o-0`}
                  data-testid={`filter-checkbox-${tag.sys.id}`}
                />
                {tag.title || tag.name}
              </label>
            )
          })}
        </div>

        <div className='d-sm-flex flex-sm-align-center mt-sm-5'>
          <div className='mr-sm-5'>
            <button
              type='button'
              className='Btn Btn--shadow'
              onClick={saveFilters}
              data-testid='save-filters-button'
            >
              {t('save')}
            </button>
          </div>

          <button
            type='button'
            onClick={() => {
              setFilters([])
              trackSpEngagement({
                eventType: 'deselect',
                unitName: filterGroup.sys.id,
                componentId: filterGroup.sys.id,
                unitLocation: 'modal',
                parentComponentId: parent.sys.id,
                positionInParentComponent: getPositionInParent(filterGroup.sys.id)
              })
            }}
            className='Btn-link primary-link text-underline'
            data-testid='clear-filters-button'
          >
            {t('clear')}
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

export default FilterButton
