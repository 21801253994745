import DynamicComponentRenderer from 'components/dynamic-page/DynamicComponentRenderer'
import { PageComponentsProvider } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { ILayoutComponentDuo } from './LayoutComponentDuo.type'

export default function LayoutComponentDuo(props: ILayoutComponentDuo) {
  const {
    title, componentOne, componentTwo, ...data
  } = props
  const { pageLayout } = useContext(PageContext)
  const childrenComponents = [componentOne, componentTwo].filter((c) => !!c)
  return (
    <div className={`${sharedStyles.sectionMargin}`}>
      <TrackImpression
        unitName={data.sys.id}
        componentId={data.sys.id}
        unitLocation='inline'
        childComponentId={childrenComponents.map((c) => c.sys.id)}
      />
      {title && <SectionHeader title={title} />}
      <PageComponentsProvider parent={data} entries={childrenComponents}>
        <div className={pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'}>
          <div className={pageLayout.columnWidthLarge === 10
            ? 'grid-col-sm-10 grid-col-md-5'
            : 'grid-col-sm-12 grid-col-md-6'}
          >
            <DynamicComponentRenderer block={componentOne} parent={props} />
          </div>
          <div
            className={`${pageLayout.columnWidthLarge === 10
              ? 'grid-col-sm-10 grid-col-md-5'
              : 'grid-col-sm-12 grid-col-md-6'}
           ${componentTwo?.title ? 'mt-sm-5 mt-md-0' : 'mt-sm-3'
              }`}
          >
            <DynamicComponentRenderer block={componentTwo} parent={props} />
          </div>
        </div>
      </PageComponentsProvider>
    </div>
  )
}
