import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentCtaButton } from './ComponentCtaButton.type'

const classByType = {
  primary: 'Btn--primary',
  shadow: 'Btn--shadow'
}

export default function ComponentCtaButton({
  ctaLabel,
  ctaLink,
  buttonType,
  sys
}: IComponentCtaButton) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent, entries } = usePageComponentsContext()
  const isLast = getPositionInParent(sys.id) === entries?.length

  if (!ctaLink || !ctaLabel) return null
  const handleClick = () => {
    trackClick({
      clickType: 'button',
      buttonName: 'cta',
      buttonText: ctaLabel,
      linkedContentUrl: ctaLink,
      unitName: sys.id,
      componentId: sys.id,
      unitLocation: 'inline',
      parentComponentId: parentComponent?.sys.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })
  }

  return (
    <div
      data-testid='cta-button-section'
      className={
        isLast ? sharedStyles.sectionMargin : 'my-sm-5 my-md-6'
      }
    >
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
        linkedContentUrl={ctaLink}
        buttonName='cta'
        buttonText={ctaLabel}
      />
      <div className='container d-sm-flex flex-sm-justify-center'>
        <Link
          onClick={handleClick}
          href={ctaLink}
          className={`Btn ${classByType[buttonType || 'shadow']} no-underline Btn--responsive`}
        >
          {ctaLabel}
        </Link>
      </div>
    </div>
  )
}
