import CONTENT_TYPE_MAP from '../rendererMapping'

export default function DynamicComponentRenderer({ block, ...props }: any) {
  if (Array.isArray(block)) {
    return (
      <>
        {block.map((b) => {
          if (!b?.sys) {
            console.warn(`Add "${b?.typename}" to mapping`)
            return null
          }

          return (
            <DynamicComponentRenderer
              key={`block-${b.sys.id}`}
              block={b}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )
        })}
      </>
    )
  }

  const contentTypeId = block?.typename
  const Component = CONTENT_TYPE_MAP[contentTypeId]?.component

  if (!Component) {
    console.warn(`Add "${contentTypeId}" to mapping`)
    return null
  }

  const { id } = block.sys

  const componentProps = {
    ...block,
    ...props
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component key={`${contentTypeId}-${id}`} {...componentProps} />
}
