import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGdownload = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 13.0013V0.00012207H9V13.0181L4 8.00002L2.6 9.40322L8.5916 15.4176C9.3704 16.1956 10.6352 16.194 11.4108 15.414L17.4 9.40322L15.9996 8.00002L11 13.0013ZM0 20.0001H20V18.0001H0V20.0001Z'
      />
    </svg>
  )
}

export default SVGdownload
