import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import AnalyticsContext from '@context/AnalyticsContext/AnalyticsContext'
import SVGexpert from '@fractal/primitives/SVGs/icons/expert'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import styles from './ComponentHomepageTestimonial.module.scss'
import { IComponentHomepageTestimonial } from './ComponentHomepageTestimonial.type'

function ShapeYellow() {
  return (
    <svg
      className='fill-background-high-ochreTint flex-sm-shrink-0 z-index-n1'
      width='596'
      height='563'
      viewBox='0 0 596 563'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* eslint-disable-next-line max-len */}
      <path d='M298.002 0.380115C227.543 -2.34285 148.223 9.017 95.5265 52.244C49.9179 89.6422 23.2109 158.95 8.52833 218.6C-7.29335 282.802 -2.35698 366.235 33.9696 422.779C61.3939 465.453 101.602 499.192 145.945 520.721C196.785 545.398 257.287 562.076 314.625 562.969C370.402 563.863 426.01 545.185 472.125 513.999C541.107 467.368 584.395 389.976 593.888 307.139C600.639 248.255 591.906 176.99 557.478 127.423C508.325 56.6262 426.811 14.6331 343.146 4.12417C328.801 2.29468 313.613 0.975762 298.002 0.380115Z' />
    </svg>

  )
}

function ShapeBlue() {
  return (
    <svg
      className='fill-background-high-midblue flex-sm-shrink-0 z-index-n1'
      width='468'
      height='468'
      viewBox='0 0 468 468'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* eslint-disable-next-line max-len */}
      <path d='M302.168 11.7966L232.575 1.52255C194.554 -4.02691 155.858 5.72214 125.036 28.6699L68.641 70.6658C37.819 93.6136 17.346 127.885 11.7966 165.832L1.52255 235.5C-4.02691 273.521 5.72214 312.217 28.6699 343.039L70.6658 399.434C93.6136 430.256 127.885 450.729 165.832 456.278L235.5 466.477C273.521 472.027 312.217 462.278 343.039 439.33L399.434 397.334C430.256 374.386 450.729 340.115 456.278 302.168L466.477 232.575C472.027 194.554 462.278 155.858 439.33 125.036L397.334 68.641C374.386 37.819 340.19 17.346 302.168 11.7966Z' />
    </svg>

  )
}

export function ComponentHomepageTestimonial({
  title, textContent, sys, cta, blobPosition, blobStyle, person, testimonial
}: IComponentHomepageTestimonial) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div className='container grid flex-sm-align-center my-sm-5 my-md-6 my-lg-4'>
      <div
        className={`${styles.gridSameLineDesktop} grid-col-sm-12 grid-col-md-10 grid-start-md-2 grid-col-lg-5
        ${blobPosition === 'Left' ? 'grid-start-lg-7' : 'grid-start-lg-2'} mb-sm-3`}
      >
        <TrackImpression
          unitName={sys.id}
          unitLocation='inline'
          componentId={sys.id}
        />
        <h2 className='lh-condensed text-bold f-sm-6 f-md-8 mb-sm-4'>{title}</h2>
        <p className='f-sm-3 f-md-5 mb-sm-5 mb-md-6'>{textContent}</p>
        <Link
          className='Btn Btn--outline Btn--responsive no-underline'
          href={'url' in cta ? cta.url : getContentUrl(cta.linkTo)}
          onClick={() => {
            trackClick({
              clickType: 'button',
              buttonName: 'cta',
              buttonText: cta.label,
              linkedContentUrl: 'url' in cta ? cta.url : getContentUrl(cta.linkTo),
              unitName: sys.id,
              componentId: sys.id,
              unitLocation: 'inline',
              parentComponentId: parentComponent?.sys.id,
              positionInParentComponent: getPositionInParent(sys.id)
            })
          }}
        >
          {cta.label}
        </Link>
      </div>

      <div
        className={`${styles.gridSameLineDesktop} ${blobStyle === 'Blue'
          ? `text-static-cream grid-col-sm-10 grid-start-sm-2 grid-col-md-6 grid-start-md-4 grid-col-lg-4 ${blobPosition === 'Left' ? 'grid-start-lg-2' : 'grid-start-lg-8'}`
          : `grid-col-sm-12 grid-col-md-8 grid-start-md-3 grid-col-lg-5 ${blobPosition === 'Left' ? 'grid-start-lg-1' : 'grid-start-lg-8'}`}
        d-sm-flex flex-sm-justify-center flex-sm-align-center p-sm-relative`}
      >
        {blobStyle === 'Yellow' && <ShapeYellow />}
        {blobStyle === 'Blue' && <ShapeBlue />}

        <div className={`p-sm-absolute ${styles.testimonial}`}>
          <figure className='m-sm-0 mb-sm-5 text-center'>
            <span className='p-sm-relative mb-sm-4 d-sm-inline-block'>
              <Image
                src={person.headshot.url}
                width={98}
                height={98}
                alt={person.fullName}
                className='o-sm-hidden circle-sm'
              />
              {person.role === 'Expert'
                && <SVGexpert width={32} height={32} className='vicon p-sm-absolute right-sm-0 bottom-sm-0 fill-static-midblueShade' />}
            </span>
            <figcaption className='f-sm-3'>
              <strong>
                {person.fullName}
                ,
                {' '}
              </strong>
              <span className='d-sm-block d-md-inline'>{person.title}</span>
            </figcaption>
          </figure>
          <blockquote className='f-sm-2 f-md-3 border-sm-0 p-sm-0 m-sm-0'>
            <p>{testimonial}</p>
          </blockquote>
        </div>
      </div>
    </div>
  )
}

export default ComponentHomepageTestimonial
