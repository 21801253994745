import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import { IPodcastEpisodeLink } from 'components/dynamic-page/shared/types/PodcastEpisodeLink.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext } from 'react'

interface IPodcastEpisodeSpotlightProps extends IEntry {
  content: IPodcastEpisodeLink
  positionInUnit: number
  spotlight?: boolean
  unitName: string
}

function PodcastEpisodeSpotlight({
  content,
  spotlight,
  positionInUnit,
  unitName
}: IPodcastEpisodeSpotlightProps) {
  const { trackClick } = useContext(AnalyticsContext)
  const url = getContentUrl(content)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  if (spotlight) return null

  return (
    <>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={url}
        unitName={unitName}
        componentId={unitName}
        unitLocation='inline'
        positionInUnit={positionInUnit}
        isContent
      />
      <div className='d-sm-flex flex-sm-align-center'>
        <div
          className={`flex-sm mr-sm-4 text-bold ${spotlight ? 'f-sm-6 f-lg-7' : 'f-sm-4 f-lg-5'
          }`}
        >
          <Link
            href={url}
            className='primary-link no-underline stretched-link lh-condensed'
            onClick={() => {
              trackClick({
                clickType: 'text',
                isContent: true,
                buttonName: null,
                linkedContentId: content.sys.id,
                linkedContentUrl: url,
                unitName,
                componentId: unitName,
                unitLocation: 'inline',
                positionInUnit,
                parentComponentId: parentComponent?.sys.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {content.title}
          </Link>
        </div>
      </div>
    </>
  )
}

export default PodcastEpisodeSpotlight
