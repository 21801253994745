import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import dynamic from 'next/dynamic'
import { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentGenericContent } from './ComponentGenericContent.type'

const RenderDynamicComponents = dynamic(() => import('@retina/RenderDynamicComponents'))

export default function ComponentGenericContent({ sys, ...props }: IComponentGenericContent) {
  const { pageLayout } = useContext(PageContext)
  const { getPositionInParent } = usePageComponentsContext()
  const positionInParent = getPositionInParent(sys.id)

  if (positionInParent === 1) {
    return <RenderDynamicComponents components={[{ sys, ...props }]} />
  }

  return (
    <div className={`${pageLayout.columnWidthLarge === 10
      ? sharedStyles.fullWidthLargeGrid10
      : sharedStyles.fullWidthLargeGrid12}`}
    >
      <RenderDynamicComponents components={[props]} />
    </div>
  )
}
