import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGresources = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='resources-svg'
    >
      <path d='M45 9.15556H35V7.11111C35 4.24889 32.8 2 30 2H20C17.2 2 15 4.24889 15 7.11111V9.15556H5C2.2 9.15556 0 11.4044 0 14.2667V42.8889C0 45.7511 2.2 48 5 48H45C47.8 48 50 45.7511 50 42.8889V14.2667C50 11.4044 47.8 9.15556 45 9.15556ZM20 7.11111H30V9.15556H20V7.11111ZM45 42.8889H5V25.5111H10.5V29.6H15.5V25.5111H34.5V29.6H39.5V25.5111H45V42.8889ZM5 20.4V14.2667H15H35H45V20.4H5Z' />
    </svg>
  )
}

export default SVGresources
