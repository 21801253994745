import styles from './ComponentHomepageHeader.module.scss'

export function ComponentHomepageHeaderShapes() {
  return (
    <svg
      className={`${styles.backgroundShape}`}
      width='940'
      height='841'
      viewBox='0 0 940 841'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1248_4791)'>
        <path
          // eslint-disable-next-line max-len
          d='M569.518 104.21C518.582 191.304 436.122 372.14 570.339 454.201C704.255 535.926 834.973 382.815 893.519 298.277C912.094 271.502 904.792 235.403 877.614 219.353L646.974 82.8218C620.582 67.1263 585.549 76.6915 569.518 104.21Z'
          className='fill-background-high-midblueShade'
        />
        <path
          // eslint-disable-next-line max-len
          d='M273.448 337.341C338.103 334.901 410.888 345.078 459.243 383.802C501.094 417.305 525.601 479.393 539.074 532.829C553.592 590.344 549.063 665.086 515.729 715.74C490.564 753.969 453.668 784.194 412.978 803.48C366.326 825.586 310.808 840.527 258.194 841.327C207.013 842.128 155.986 825.395 113.67 797.457C50.3703 755.684 10.6484 686.354 1.93744 612.145C-4.25702 559.395 3.75702 495.553 35.3488 451.15C80.4521 387.728 155.25 350.109 232.023 340.695C245.186 339.056 259.124 337.874 273.448 337.341Z'
          className='fill-background-high-ochreTint'
        />
        <path
          // eslint-disable-next-line max-len
          d='M424.68 283.183C399.428 288.445 348.551 258.635 326.269 243.072C300.117 227.61 247.215 190.619 244.828 166.36C241.844 136.037 286.314 73.3559 373.151 45.9326C459.989 18.5092 514.875 55.8208 526.774 66.9062C538.673 77.9916 547.336 107.556 539.813 129.471C532.29 151.387 478.212 249.983 471.515 259.857C464.817 269.731 456.244 276.605 424.68 283.183Z'
          className='fill-background-high-scarletLite'
        />
      </g>
      <defs>
        <clipPath id='clip0_1248_4791'>
          <rect width='940' height='841' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ComponentHomepageHeaderShapes
