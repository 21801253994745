import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IArticleLink } from 'components/dynamic-page/shared/types/ArticleLink.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext } from 'react'

interface IArticleItemProps {
  article: IArticleLink
  positionInUnit: number
  unitName: string
}

export default function ArticleItem({
  article,
  positionInUnit,
  unitName
}: IArticleItemProps) {
  const { trackClick } = useContext(AnalyticsContext)
  const articleUrl = getContentUrl(article)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  const handleClick = () => {
    trackClick({
      clickType: 'text',
      isContent: true,
      linkText: article.title,
      linkedContentId: article.sys.id,
      linkedContentUrl: articleUrl,
      unitName,
      componentId: unitName,
      unitLocation: 'inline',
      positionInUnit,
      unitVariation: 'article_content',
      parentComponentId: parentComponent?.sys?.id,
      positionInParentComponent: getPositionInParent(unitName)
    })
  }

  return (
    <>
      <TrackImpression
        as='div'
        key={articleUrl}
        linkedContentUrl={articleUrl}
        linkedContentId={article.sys.id}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={positionInUnit}
        unitVariation='article_content'
        isContent
        linkText={article.title}
      />
      <div className='flex-sm'>
        <Link
          href={articleUrl}
          className='f-sm-3 f-lg-4 primary-link no-underline stretched-link lh-condensed'
          onClick={handleClick}
        >
          {article.title}
        </Link>
      </div>
    </>
  )
}
