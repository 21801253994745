import { AnalyticsContext } from '@context/AnalyticsContext'
import useTranslation from 'app/i18n/client'
import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext, useEffect, useState } from 'react'
import { useMedia } from 'the-platform'
import SectionHeader from '../../shared/components/SectionHeader'
import StyledButton from '../../shared/components/StyledButton'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import styles from './ComponentResourceList.module.scss'
import { IComponentResourceList } from './ComponentResourceList.type'
import MapResourceContent from './MapResourceContent'

const STEP = 3

export default function ComponentResourceList({
  contentLimit,
  contentType,
  filterByCollection,
  title,
  sys,
  contentListCollection,
  externalFilter
}: IComponentResourceList) {
  const isDesktop = useMedia({ minWidth: 1024 })
  const [limit, setLimit] = useState(6)
  const { trackClick } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)
  const { parent, getPositionInParent } = usePageComponentsContext()
  const resourceList = getData(sys.id)

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit,
      contentType,
      filterByCollection,
      contentListCollection,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [
    sys.id,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  ])

  useEffect(() => {
    setLimit(isDesktop ? 6 : 4)
  }, [isDesktop])

  const handleLoadMore = () => {
    trackClick({
      clickType: 'button',
      buttonName: 'load_more',
      buttonText: t('explore-more'),
      unitName: sys.id,
      unitLocation: 'inline',
      componentId: sys.id,
      parentComponentId: parent?.sys.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })
    setLimit((prev) => prev + STEP)
  }

  return (
    <>
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
      />
      <div
        data-testid='component-resource-list'
        className={sharedStyles.sectionMargin}
      >
        <div className='grid-col-sm-12 grid-col-lg-10 grid-start-lg-2'>
          <SectionHeader title={title} isLoading={isSectionLoading(sys.id)} />

          <ul className={`grid list-reset m-sm-0 ${styles.customGap}`}>
            {resourceList.map((content, index) => {
              if (index > limit - 1) return null

              return (
                <li
                  key={content.sys.id}
                  className={`grid-col-sm-12 grid-col-md-6 grid-col-lg-4 d-sm-flex mx-sm-n4 mx-md-0 ${styles.list}`}
                >
                  <MapResourceContent
                    content={content}
                    index={index}
                    unitName={sys.id}
                  />
                </li>
              )
            })}
          </ul>

          {limit < resourceList.length && (
            <div className='mt-sm-5 text-center no-print'>
              <StyledButton
                onClick={() => handleLoadMore()}
                data-testid='resource-list-explore-more'
              >
                <TrackImpression
                  unitName={sys.id}
                  unitLocation='inline'
                  componentId={sys.id}
                  buttonName='load_more'
                  buttonText={t('explore-more')}
                />
                {t('explore-more')}
              </StyledButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
