import { Image } from '@/journey/Image'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import React from 'react'
import styles from './ComponentHomepageFeaturedSection.module.scss'
import { IComponentHomepageFeaturedSection } from './ComponentHomepageFeaturedSection.type'

export function ComponentHomepageFeaturedSection({
  sys, title, imageListCollection, numberOfColumnsInMobile
}: IComponentHomepageFeaturedSection) {
  return (
    <div className='container mt-sm-6 mb-sm-5 mb-md-7'>
      <TrackImpression
        componentId={sys.id}
        unitName={sys.id}
        unitLocation='inline'
      />
      <h3 className='text-regular text-center f-sm-2 f-md-3 mb-sm-4'>{title}</h3>
      {imageListCollection.items?.length > 0 && (
        <div className='grid'>
          <ul
            className={`grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-col-lg-12 list-reset m-sm-0 ${styles.imgContainer}`}
            style={{
              '--column-count-sm': numberOfColumnsInMobile || 3,
              '--column-count-lg': imageListCollection.items.length
            } as any}
          >
            {imageListCollection.items.map((imageItem) => (
              <li
                key={imageItem.sys.id}
                className=''
              >
                <Image
                  src={imageItem}
                  className={styles.image}
                  width={200}
                  height={200}
                  alt=''
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ComponentHomepageFeaturedSection
