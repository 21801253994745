import { Carousel } from '@/journey/Carousel'
import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import MapContent from 'components/dynamic-page/shared/components/MapContent'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext, useEffect } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentFilteredResources } from '../ComponentFilteredResources/ComponentFilteredResources.type'
import styles from './ComponentCardCarousel.module.scss'
import { IComponentCardCarousel } from './ComponentCardCarousel.type'

function ComponentCardCarousel({
  sys,
  title,
  subtitle,
  type,
  contentLimit,
  contentType,
  filterByCollection,
  contentListCollection,
  externalFilter
}: IComponentCardCarousel) {
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)
  const resourceList = getData(sys.id)

  const { parent: parentComponent } = usePageComponentsContext()
  const filteredResourcesParent = parentComponent?.typename === 'ComponentFilteredResources'
    ? parentComponent as IComponentFilteredResources : null

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit,
      contentType,
      filterByCollection,
      contentListCollection,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [
    sys.id,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  ])

  return (
    <div
      data-testid='horizontal-content-section'
      className={`${!filteredResourcesParent ? sharedStyles.sectionMargin : ''}`}
    >
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
      />

      <SectionHeader
        icon={type}
        title={title}
        subtitle={subtitle}
        level={filteredResourcesParent?.title ? 'h3' : 'h2'}
        isLoading={isSectionLoading(sys.id)}
      />

      <div className={styles.fullWidth}>
        <Carousel
          slides={resourceList.map((content, index) => (
            <MapContent
              content={content}
              isVideo={type === 'watch'}
              index={index}
              unitName={sys.id}
            />
          ))}
        />
      </div>
    </div>
  )
}

export default ComponentCardCarousel
