import { Image } from '@/journey/Image'
import { RichText } from '@fractal/primitives/atoms/RichText'
import useTranslation from 'app/i18n/client'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import WunderSmartLink from 'components/dynamic-page/shared/components/WunderSmartLink'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentWunderBanner } from './ComponentWunderBanner.type'

export default function ComponentWunderBanner({
  sys,
  image,
  description
}: IComponentWunderBanner) {
  const { t } = useTranslation()

  return (
    <div data-testid='wunder-section' className={sharedStyles.sectionMargin}>
      <div className='d-sm-flex bg-background-high-peachTint rounded-sm-3 border-sm border-background-high-scarletLite'>
        <TrackImpression
          unitName={sys.id}
          componentId={sys.id}
          unitLocation='inline'
        />
        <div className='d-sm-none d-lg-flex ml-sm-3'>
          <Image
            alt=''
            src={image.url}
            width={310}
            height={300}
            className='of-sm-cover'
          />
        </div>
        <div
          className='p-sm-5 py-md-6 f-sm-5'
          style={{ maxWidth: 540, margin: 'auto' }}
        >
          <div className='mb-sm-4'>
            <RichText richTextContent={description.json} richTextLinks={description.links} unstyled />
          </div>
          <div className='text-center'>
            <WunderSmartLink
              href='/wunder'
              label={t('download-the-wunder-app')}
              unitLocation='inline'
              unitName={sys.id}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
