import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import ArticleResourceContent from './ArticleResourceContent'
import DefaultContent from './DefaultContent'
import DownloadableAssetContent from './DownloadableAssetContent'

function MapResourceContent({ content, unitName, index }: any) {
  switch (content.__typename) {
    case 'Article':
      return (
        <ArticleResourceContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'DownloadableAsset':
      return (
        <DownloadableAssetContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'PressRelease':
      return (
        <DefaultContent
          title={content.title}
          sys={content.sys}
          dek={content.pressReleaseDek}
          url={getContentUrl(content)}
          unitName={unitName}
          index={index}
        />
      )
    case 'ResearchAndSurveys':
      return (
        <DefaultContent
          title={content.researchTitle}
          sys={content.sys}
          dek={content.researchDek}
          url={getContentUrl(content)}
          unitName={unitName}
          index={index}
        />
      )
    case 'NewsAppearance':
      return (
        <DefaultContent
          title={content.newsAppearanceTitle}
          dek={content.newsAppearanceNameOfThePublication}
          sys={content.sys}
          isExternalLink
          url={getContentUrl(content)}
          unitName={unitName}
          index={index}
        />
      )
    default:
      return null
  }
}

export default MapResourceContent
