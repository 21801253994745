import { RichText } from '@fractal/primitives/atoms/RichText'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentAtAGlance } from './ComponentAtAGlance.type'

export default function ComponentAtAGlance({
  sys,
  title,
  body
}: IComponentAtAGlance) {
  const { pageLayout } = useContext(PageContext)

  if (!body.json) return null

  const id = `page-at-a-glance-${sys.id}`

  return (
    <div
      className={`${pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'} my-sm-5 my-md-6`}
      data-testid='body-text-section'
    >
      <TrackImpression
        unitName={sys.id}
        unitLocation='header'
        componentId={sys.id}
      />
      <div
        className={
          pageLayout.columnWidthLarge === 10
            ? 'grid-col-sm-10 grid-start-lg-3 grid-col-lg-6'
            : 'grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'
        }
      >
        <div className='bg-background-high-white width-sm-full rounded-md-1 p-sm-5 p-md-6'>
          <h3 className='mb-sm-5 f-sm-6 text-bold text-center'>{title}</h3>
          <div id={id} className='f-sm-3 f-md-5'>
            <RichText richTextContent={body.json} richTextLinks={body.links} unstyled />
          </div>
        </div>
      </div>
    </div>
  )
}
