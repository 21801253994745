import { Image } from '@/journey/Image'
import { SVGborderTriangleDown } from '@/journey/Svgs/borders'
import { AnalyticsContext } from '@context/AnalyticsContext'
import RichText from '@fractal/primitives/atoms/RichText/RichText'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import ArrowLink from './ArrowLink'
import AvatarRow from './AvatarRow'
import styles from './ComponentHeroHeader.module.scss'
import { IComponentHeroHeader } from './ComponentHeroHeader.type'

const fallbackAvatars = [
  'https://images.ctfassets.net/p0qf7j048i0q/6w8FAz54Q46RX0B01dwZ0V/2209f0e0fd66dbb8dd365728209b990e/KarenWilson.png',
  // eslint-disable-next-line max-len
  'https://images.ctfassets.net/p0qf7j048i0q/1NAiE25NVj8cKK5UKqqcfX/41676dc6d0130e4bde4649ad34e54a16/Bio_460x460_andy.png',
  'https://images.ctfassets.net/p0qf7j048i0q/EcW3tsN7PlDBbjHWLxMRF/2fe49681bfdba888f5b691fb56c65836/Ellen_Braaten.png'
]

const unitName = 'header_secondary'
const defaultBackgroundColor = 'peachTint'

export default function ComponentHeroHeader({
  sys,
  title,
  description,
  image,
  expertsCta,
  backgroundColor: bgColor,
  hideJumpTo,
  rubricLabel,
  expertsCollection
}: IComponentHeroHeader) {
  const { trackClick } = useContext(AnalyticsContext)
  const { headers } = useContext(PageContext)
  const { t } = useTranslation()
  const anchors = headers
    .filter((h) => h.level === 'h2')
    .filter((h, index) => index > 0)
  const backgroundColor = !bgColor ? defaultBackgroundColor : bgColor
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const expertAvatars = expertsCollection.items.map((expert) => expert.headshot?.url).filter((url) => !!url)

  return (
    <div data-testid='header-section'>
      <TrackImpression
        unitName={sys.id}
        unitLocation='header'
        componentId={sys.id}
      />
      <div
        className={`bg-background-low-${backgroundColor} py-sm-5 pb-md-6 o-sm-hidden`}
      >
        <div
          className={`container grid flex-sm-align-center ${styles.content}`}
        >
          <div className={`${styles.title} grid-align-self-lg-end`}>
            <h1
              id='target-skip-to-content'
              className='f-sm-8 f-md-11 lh-condensed'
            >
              {!!rubricLabel && (
                <span className='d-sm-block mb-sm-3 mb-md-4 f-sm-3 f-md-5 text-foreground-midblueShade'>
                  {rubricLabel}
                </span>
              )}
              {title}
            </h1>
          </div>
          {!!image && (
            <div
              className={`${styles.image} d-sm-none d-lg-flex grid-align-self-lg-end`}
            >
              <div className='w-sm-full flex-lg-self-end'>
                <div className={`${styles.imageContainer} p-sm-relative`}>
                  <Image
                    alt=''
                    fill
                    src={image.url}
                    priority
                    sizes='444px'
                  />
                </div>
              </div>
            </div>
          )}
          <div className={`${styles.content} grid-align-self-lg-start`}>
            {!!description && (
              <div className='f-sm-4 f-md-5'>
                <RichText richTextContent={description.json} richTextLinks={description.links} unstyled />
              </div>
            )}
            {!!expertsCta && (
              <div className='mt-sm-5'>
                <div className='d-sm-flex flex-sm-align-center'>
                  <AvatarRow srcs={!expertAvatars.length ? fallbackAvatars : expertAvatars} className='mr-sm-3' />
                  <div>
                    <ArrowLink
                      href='https://www.understood.org/our-experts'
                      unitName={unitName}
                      unitLocation='header'
                      buttonName='our_experts'
                      className='d-sm-block lh-condensed'
                    >
                      {t('meet-our-experts-on-this-topic')}
                    </ArrowLink>
                  </div>
                </div>
              </div>
            )}
            {!hideJumpTo && (
              <div className='mt-sm-5'>
                <span className='d-sm-block f-sm-3 f-md-2 text-italic mb-md-2'>
                  {t('jump-to-section')}
                  :
                </span>
                <div>
                  <ul
                    className={`mx-sm-n5 mx-md-0 my-sm-0 px-sm-5 px-md-0 list-reset d-sm-flex o-sm-auto 
                      ${styles.listContainer}`}
                  >
                    {anchors.map((anchor, index) => (
                      <li className='mr-sm-2 pt-sm-3 pt-lg-0' key={anchor.id}>
                        <a
                          className='f-sm-2 px-sm-4 Btn Btn--primary Btn--small no-underline'
                          href={`#${anchor.id}`}
                          onClick={() => {
                            trackClick({
                              clickType: 'text',
                              isContent: false,
                              linkText: anchor.text,
                              linkedContentUrl: anchor.id,
                              unitName: 'jumplink',
                              componentId: sys.id,
                              unitLocation: 'header',
                              positionInUnit: index + 1,
                              parentComponentId: parentComponent?.sys.id,
                              positionInParentComponent: getPositionInParent(sys.id)
                            })
                          }}
                        >
                          <TrackImpression
                            key={anchor.id}
                            linkedContentUrl={anchor.id}
                            unitName='jumplink'
                            componentId={sys.id}
                            unitLocation='header'
                            positionInUnit={index + 1}
                            linkText={anchor.text}
                          />
                          {anchor.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SVGborderTriangleDown
        pathfillColor={`background-low-${backgroundColor}`}
        id='headerBorder'
      />
    </div>
  )
}
