import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { PageComponentsProvider } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentContentFilter } from './ComponentContentFilter.type'
import FilterButton from './FilterButton'

const QUERY_PARAM = 'content-filters'

export default function ComponentContentFilter({
  title,
  filterGroupsCollection,
  defaultFilterByCollection,
  sys,
  ...props
}: IComponentContentFilter) {
  const router = useRouter()
  const { registerFilter, unregisterFilter } = useContext(
    ContentProviderContext
  )
  const [filters, setFilters] = useState({
    default: defaultFilterByCollection?.items || []
  })
  const [isFilterInitialized, setIsFilterInitialized] = useState(false)

  const getFiltersFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const base64String = searchParams.get(QUERY_PARAM)
    let contentFilter

    if (base64String) {
      try {
        const decodedString = Buffer.from(base64String, 'base64').toString()
        contentFilter = JSON.parse(decodeURIComponent(decodedString))
      } catch (e) {
        console.log('error', e)
      }
    }

    return contentFilter
  }

  const appendFilterToUrl = (filtersToAppend) => {
    const searchParams = new URLSearchParams(window.location.search)
    const newFilterState = Object.entries(filtersToAppend).reduce(
      (acc, [k, v]: any) => {
        if (k === 'default') return acc
        return {
          ...acc,
          [k]: v.map((item) => item.sys.id)
        }
      },
      {}
    )
    const base64String = btoa(
      encodeURIComponent(JSON.stringify(newFilterState))
    )
    searchParams.set(QUERY_PARAM, base64String)
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, ...Object.fromEntries(searchParams) }
      },
      undefined,
      { scroll: false, shallow: true }
    )
  }

  const getFiltersFromLocalStorage = () => {
    const localStorageFiltersString = localStorage.getItem(QUERY_PARAM)
    let localStorageFilters = {}
    if (localStorageFiltersString) {
      try {
        const localStorageFiltersGroup = JSON.parse(localStorageFiltersString)
        localStorageFilters = localStorageFiltersGroup[sys.id]
      } catch (e) {
        console.error(e)
      }
    }

    return localStorageFilters
  }

  const saveFiltersToLocalStorage = (filtersToSave) => {
    const localStorageFiltersString = localStorage.getItem(QUERY_PARAM)
    let localStorageFiltersGroup = {}
    if (localStorageFiltersString) {
      try {
        localStorageFiltersGroup = JSON.parse(localStorageFiltersString)
      } catch (e) {
        console.error(e)
      }
    }

    // Remove the default filter group from the filters
    const newFilterState = Object.entries(filtersToSave).reduce(
      (acc, [k, v]: any[]) => {
        if (k === 'default') return acc
        return {
          ...acc,
          [k]: v.map((item) => item.sys.id)
        }
      },
      {}
    )

    localStorage.setItem(
      QUERY_PARAM,
      JSON.stringify({ ...localStorageFiltersGroup, [sys.id]: newFilterState })
    )
  }

  useEffect(() => {
    let contentFilters = getFiltersFromUrl()
    if (!contentFilters) {
      contentFilters = getFiltersFromLocalStorage() || {}
    }

    // Get all the tags from the filter groups
    const allTags = filterGroupsCollection.items
      .map((group) => group.tagsCollection.items)
      .flat()

    // Get the full tag objects from the filterGroupsCollection
    const newFilters = Object.entries(contentFilters).reduce(
      (acc, [k, v]: any) => {
        // Map tag ids to tag objects
        const tags = v.map((t) => allTags.find((item) => item.sys.id === t))
        if (!tags?.length) return acc
        return {
          ...acc,
          [k]: tags
        }
      },
      {}
    )

    const newFilterState = {
      ...newFilters,
      default: defaultFilterByCollection.items || []
    }

    setFilters(newFilterState)
    registerFilter(sys.id, Object.values(newFilterState).flat())
    saveFiltersToLocalStorage(newFilterState)
    setIsFilterInitialized(true)

    // Was having a race condition where the url gets overwritten by other code
    // We should probably find a better way to do this, maybe create a hook to batch updates
    setTimeout(() => appendFilterToUrl(newFilterState), 1000)

    return () => {
      unregisterFilter(sys.id)
    }
  }, [])

  const onFilterChange = (id, filter) => {
    const newFilterState = { ...filters, [id]: filter }
    setFilters(newFilterState)
    registerFilter(sys.id, Object.values(newFilterState).flat())
    appendFilterToUrl(newFilterState)
    saveFiltersToLocalStorage(newFilterState)
  }

  return (
    <div
      data-testid='content-filter-section'
      className={`${sharedStyles.sectionMargin}`}
    >
      <TrackImpression
        as='span'
        unitName={sys.id}
        componentId={sys.id}
        unitLocation='inline'
        childComponentId={filterGroupsCollection.items.map(
          (item) => item.sys.id
        )}
      />
      <PageComponentsProvider
        parent={{ sys, ...props }}
        entries={filterGroupsCollection.items}
      >
        <h2 className='mb-sm-3 f-sm-3 f-md-2 text-italic text-regular'>
          {title}
        </h2>

        <div className='grid-col-sm-12 d-sm-flex flex-sm-column flex-md-row'>
          {isFilterInitialized && filterGroupsCollection.items
            .map((item) => (
              <FilterButton
                filterGroup={item}
                onFilterChange={(newFilters) => {
                  onFilterChange(item.sys.id, newFilters)
                }}
                key={item.sys.id}
                savedFilters={filters[item.sys.id] || []}
              />
            ))}
        </div>
      </PageComponentsProvider>
    </div>
  )
}
