import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGaltWave = ({ className = '' }: ISVGsProps) => {
  return (
    <>
      <svg
        className={`d-sm-block d-md-none ${className}`}
        viewBox='0 0 1280 84'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        focusable='false'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 84H1280V50.4246C1280 50.4246 1177.6 0 952.32 0C727.04 0 651.947 50.4246 375.467 50.4246C98.9867 50.4246 0 0 0 0L0 84Z'
          fill=''
        />
      </svg>
      <svg
        className={`d-sm-none d-md-block ${className}`}
        viewBox='0 0 1280 84'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 84H1280V50.4246C1280 50.4246 1177.6 0 952.32 0C727.04 0 651.947 50.4246 375.467 50.4246C98.9867 50.4246 0 0 0 0L0 84Z'
          fill=''
        />
      </svg>
    </>
  )
}

export default SVGaltWave
