import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGarrowRight from '@fractal/primitives/SVGs/icons/arrowRight'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'

interface IArrowLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  unitName: string
  unitLocation: 'header' | 'inline'
  buttonName?: string
}

function ArrowLink({
  href,
  children,
  unitName,
  unitLocation,
  buttonName,
  ...props
}: IArrowLinkProps) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent, getPositionInParent } = usePageComponentsContext()

  return (
    <Link
      data-testid='arrow-link'
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      href={href}
      className={`primary-link no-underline flex-sm-align-center ${props?.className || ''
        }`}
      onClick={(e) => {
        trackClick({
          clickType: 'text',
          componentId: unitName,
          isContent: true,
          buttonName,
          linkedContentUrl: href,
          unitName,
          unitLocation,
          parentComponentId: parent?.sys.id,
          positionInParentComponent: getPositionInParent(unitName)
        })
        props?.onClick?.(e)
      }}
    >
      <TrackImpression
        key={href}
        componentId={unitName}
        linkedContentUrl={href}
        unitName={unitName}
        unitLocation={unitLocation}
        linkText={buttonName}
        isContent
      />
      {children}
      <SVGarrowRight
        className='vicon ml-sm-2 text-foreground-scarlet vertical-align-sm-middle'
        width={20}
        height={20}
      />
    </Link>
  )
}

export default ArrowLink
