import { AnalyticsContext } from '@context/AnalyticsContext'
import useTranslation from 'app/i18n/client'
import { useContext } from 'react'
import { TrackImpression } from '../TrackImpression'
import styles from './Filter.module.scss'

interface FilterProps {
  unitName?: string
  unitVariation?: string
  onChange: (value: string) => void,
  contentFilter: string
}

function Filter({
  unitName, unitVariation, contentFilter, onChange
}: FilterProps) {
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const { t } = useTranslation()

  return (
    <TrackImpression
      as='div'
      unitName={unitName}
      unitLocation='inline'
      buttonName='filter'
      unitVariation={unitVariation}
      className='no-print'
      componentId={unitName}
    >
      <select
        data-testid='filter-select'
        className={`${styles.select}
          border-foreground-grey4 rounded-sm-1 border-shadow bg-background-high-white text-foreground-darkblue
        `}
        value={contentFilter}
        onChange={(e) => {
          onChange?.(e.target.value)
          trackSpEngagement({
            eventType: 'select',
            unitName,
            componentId: unitName,
            unitLocation: 'inline',
            unitVariation,
            submittedObject: { filter: e.target.value }
          })
        }}
      >
        <option value='' disabled hidden>
          {t('filter-by-ages')}
        </option>
        <option value='all_ages'>{t('for-everyone')}</option>
        <option value='kids'>{t('for-parents-of-kids')}</option>
        <option value='teens'>{t('for-parents-of-teens')}</option>
      </select>
    </TrackImpression>
  )
}

export default Filter
