import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGarrowLeft = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.0001 40L16.4921 43.5L1.45613 28.521C-0.488871 26.574 -0.484871 23.412 1.46513 21.473L16.4921 6.5L20.0001 10.001L7.49697 22.5H50.0001V27.5H7.45505L20.0001 40ZM5.00013 25.0539V24.996L4.97113 25.025L5.00013 25.0539Z'
      />
    </svg>
  )
}

export default SVGarrowLeft
