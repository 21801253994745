import SVGdownload from '@/journey/Svgs/icons/download'
import SVGpodcast from '@/journey/Svgs/icons/podcast'
import SVGresources from '@/journey/Svgs/icons/resources'
import { IContentfulIcon } from './ContentfulIcon.type'

function ContentfulIcon(props: IContentfulIcon) {
  const { name, className = 'vicon' } = props

  switch (name) {
    case 'download':
      return <SVGdownload className={className} />
    case 'resources':
      return <SVGresources className={className} />
    case 'podcast':
      return <SVGpodcast className={className} />
    default:
      return null
  }
}

export default ContentfulIcon
