import { Image } from '@/journey/Image'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IAsset } from 'components/dynamic-page/shared/types/Asset.type'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import React, { useContext } from 'react'
import sharedStyles from '../../../shared/styles/DynamicComponents.module.scss'

export interface ILargeImage extends IEntry {
  content: IAsset
}

export default function LargeImage({ content, sys }: ILargeImage) {
  const { pageLayout } = useContext(PageContext)

  return (
    <div
      className={`${pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'
      } mb-sm-6 mt-sm-3 mt-md-6`}
      data-testid='large-hero'
    >
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
      />
      <div
        className={`p-sm-relative rounded-md-1 ${pageLayout.columnWidthLarge === 10
          ? 'grid-col-sm-10 grid-start-lg-2'
          : 'grid-col-sm-12 grid-start-lg-3'
        } grid-col-lg-8 mx-sm-n5 mx-md-0`}
      >
        <div className='p-sm-relative' style={{ paddingTop: '60%' }}>
          <Image
            className='of-sm-cover rounded-md-1'
            src={content.url}
            alt=''
            fill
            priority
          />
        </div>
      </div>
    </div>
  )
}
