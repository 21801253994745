import { Link } from '@/journey/Link'
import AnalyticsContext from '@context/AnalyticsContext/AnalyticsContext'
import VideoPlayer from 'components/articles/VideoPlayer'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import styles from './ComponentHomepageVideo.module.scss'
import { IComponentHomepageVideo } from './ComponentHomepageVideo.type'

function Shape1() {
  return (
    <svg
      className={`p-sm-absolute left-sm-0 bottom-sm-0 fill-background-high-scarletTint z-index-n1 ${styles.shape1}`}
      width='776'
      height='111'
      viewBox='0 0 776 111'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 111C98.3905 27.8466 330.893 -90.4507 776 111H0Z' />
    </svg>
  )
}

function Shape2() {
  return (
    <svg
      className={`p-sm-absolute right-sm-0 top-sm-0 fill-background-high-ochreTint ${styles.shape2}`}
      width='139'
      height='137'
      viewBox='0 0 139 137'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1248_7651)'>
        <path d='M-2.38799e-06 82.3691L0 137L54.2496 137L54.2496 82.3691L-2.38799e-06 82.3691Z' />
        <path d='M28.5864 55.104L28.5864 109.735L82.8361 109.735L82.8361 55.104L28.5864 55.104Z' />
        <path d='M56.1654 27.2649L56.1654 81.8958L110.415 81.8957L110.415 27.2649L56.1654 27.2649Z' />
        <path d='M84.7518 -0.000247955L84.7518 54.6306L139.001 54.6306L139.001 -0.000250327L84.7518 -0.000247955Z' />
      </g>
      <defs>
        <clipPath id='clip0_1248_7651'>
          <rect width='137' height='139' fill='white' transform='translate(0 137) rotate(-90)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function ComponentHomepageVideo({
  title, textContent, sys, video, cta
}: IComponentHomepageVideo) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const url = new URL(video.videoUrl || '')
  const searchParams = new URLSearchParams(url.search)
  searchParams.set('w', '4')
  searchParams.set('h', '3')
  url.search = searchParams.toString()

  return (
    <div className='z-index-1 p-sm-relative bg-background-low-white mt-sm-6 mb-sm-7 mt-md-7 mb-md-8 mt-lg-8 pt-sm-7 pb-sm-6 pt-lg-8 pb-lg-8'>
      <div className='container grid flex-lg-align-center'>
        <div className='grid-col-sm-12 grid-col-md-10 grid-start-md-2 grid-col-lg-5 grid-start-lg-1 mb-sm-5 mb-lg-0'>
          <TrackImpression
            unitName={sys.id}
            unitLocation='inline'
            componentId={sys.id}
          />
          <h2 className='lh-condensed text-bold f-sm-8 f-md-10 mb-sm-5 mb-md-4'>{title}</h2>
          <p className='f-sm-3 f-md-5 mb-sm-5 mb-md-6'>{textContent}</p>
          <Link
            className='Btn Btn--outline Btn--responsive no-underline'
            href={getContentUrl(cta.linkTo)}
            onClick={() => {
              trackClick({
                clickType: 'button',
                buttonName: 'cta',
                buttonText: cta.label,
                linkedContentUrl: getContentUrl(cta.linkTo),
                unitName: sys.id,
                componentId: sys.id,
                unitLocation: 'inline',
                parentComponentId: parentComponent?.sys.id,
                positionInParentComponent: getPositionInParent(sys.id)
              })
            }}
          >
            {cta.label}
          </Link>
        </div>
        <div className='grid-col-sm-12 grid-col-md-10 grid-start-md-2 grid-col-lg-6 grid-start-lg-7 p-sm-relative'>
          <Shape2 />
          <div className='mx-sm-n5 mx-md-0 rounded-md-1 o-sm-hidden'>
            <VideoPlayer
              url={url.toString()}
              videoId={video.sys.id}
              fullSizeContainer
              applyAspectRatio
            />
          </div>
        </div>
      </div>
      <Shape1 />
    </div>
  )
}

export default ComponentHomepageVideo
