import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGfacebook = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      data-testid='facebook-svg'
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.025 8.3025L38.9 8.3V0.35C38.0575 0.2425 35.1625 0 31.795 0C24.765 0 19.9525 4.1425 19.9525 11.75V18.305H12V27.1925H19.9525V50H29.4625V27.1925H37.3925L38.58 18.305H29.4625V12.63C29.4625 10.0575 30.2025 8.3025 34.025 8.3025Z'
      />
    </svg>
  )
}

export default SVGfacebook
