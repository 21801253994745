import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGdownload from '@fractal/primitives/SVGs/icons/download'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import SubscribeModal from 'components/dynamic-page/shared/components/SubscribeModal'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IDownloadableAssetLink } from 'components/dynamic-page/shared/types/DownloadableAssetLink.type'
import { useContext, useState } from 'react'

interface IArticleItemProps {
  content: IDownloadableAssetLink
  positionInUnit: number
  unitName: string
  emailTags: string[]
}

export default function DownloadItem({
  content,
  positionInUnit,
  unitName,
  emailTags
}: IArticleItemProps) {
  const { trackClick, trackSpEngagement } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const { isEmailProvided } = useContext(PageContext)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  const handleDownload = () => {
    trackSpEngagement({
      eventType: 'download',
      unitName,
      componentId: unitName,
      unitLocation: 'inline',
      linkedContentId: content.sys.id,
      linkedContentUrl: content.asset.url,
      unitVariation: 'download_content',
      positionInUnit,
      parentComponentId: parentComponent?.sys?.id,
      positionInParentComponent: getPositionInParent(unitName)
    })

    fetch(content.asset.url).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = content.title
        a.click()
      })
    })
  }

  const handleClick = () => {
    trackClick({
      clickType: 'button',
      isContent: true,
      buttonName: 'download',
      buttonText: t('download'),
      linkedContentId: content.sys.id,
      linkedContentUrl: content.asset.url,
      unitName,
      componentId: unitName,
      unitLocation: 'inline',
      positionInUnit,
      unitVariation: 'download_content',
      parentComponentId: parentComponent?.sys?.id,
      positionInParentComponent: getPositionInParent(unitName)
    })
    if (isEmailProvided) {
      handleDownload()
    } else {
      setIsModalOpened(true)
    }
  }

  return (
    <>
      <TrackImpression
        as='div'
        key={content.asset.url}
        linkedContentUrl={content.asset.url}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={positionInUnit}
        unitVariation='download_content'
        buttonName='download'
        buttonText={t('download')}
        linkedContentId={content.sys.id}
        isContent
      />
      <p className='f-sm-3 f-lg-4 lh-condensed text-bold flex-sm mr-sm-3'>
        {content.title}
        <span className='d-sm-block mt-sm-2 f-sm-1 text-foreground-slate text-regular'>
          {t('PDF')}
        </span>
      </p>
      <div>
        <button
          type='button'
          className='stretched-link Btn Btn--primary Btn--small no-underline f-sm-2'
          onClick={handleClick}
        >
          <SVGdownload className='vicon' width={12} height={12} />
          {t('download')}
        </button>
      </div>
      <SubscribeModal
        unitVariation={unitName}
        isModalOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        onSubmit={() => {
          setIsModalOpened(false)
          handleDownload()
        }}
        copy={{
          bold: t('want-to-download-free-resources'),
          regular: t('subscribe-to-our-email-newsletter'),
          noText: t('i-will-just-download')
        }}
        tags={emailTags}
      />
    </>
  )
}
