import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGarrowRight from '@fractal/primitives/SVGs/icons/arrowRight'
import RichText from '@fractal/primitives/atoms/RichText'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import React, { useContext } from 'react'
import styles from './ComponentHomepageImpact.module.scss'
import { IComponentHomepageImpact } from './ComponentHomepageImpact.type'

export function ComponentHomepageImpact({
  title, byline, contentListCollection, sys
}: IComponentHomepageImpact) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div className='mt-sm-7 mt-md-8 mb-sm-7 mb-lg-8'>
      <div className='container'>
        <h2 className='lh-condensed f-sm-8 f-md-10 mb-sm-5 text-md-center'>{title}</h2>
        <p className='f-sm-3 f-md-5 text-md-center'>{byline}</p>
      </div>

      <div className='container-lg'>
        <ul className={`grid-lg list-reset mx-sm-0 mt-sm-5 mt-md-6 d-sm-flex o-sm-auto ${styles.list}`}>
          {contentListCollection?.items?.map((impactItem, index) => {
            const url = 'url' in impactItem.cta ? impactItem.cta.url : getContentUrl(impactItem.cta.linkTo)

            return (
              <li
                key={impactItem.sys.id}
                className={`p-sm-relative grid-col-lg-4 rounded-sm-1 bg-background-low-white px-sm-4 px-md-5 ${styles.listItem}
                  py-sm-5 py-md-6 d-sm-flex flex-sm-column flex-sm-shrink-0`}
              >
                <div className='f-sm-3 f-md-5 text-center'>
                  <RichText richTextContent={impactItem.body.json} richTextLinks={impactItem.body.links} unstyled />
                </div>
                <div className='my-sm-4 my-md-5 flex-sm-grow-1 d-sm-flex flex-sm-align-center'>
                  <Image
                    className='w-sm-full h-sm-auto'
                    src={impactItem.image.url}
                    alt=''
                    sizes='250px'
                    width={285}
                    height={219}
                  />
                </div>

                <TrackImpression
                  unitName={sys.id}
                  unitLocation='inline'
                  componentId={impactItem.sys.id}
                  className='text-center'
                >
                  <Link
                    className='stretched-link primary-link f-sm-3 no-underline'
                    href={url}
                    onClick={() => {
                      trackClick({
                        clickType: 'button',
                        isContent: false,
                        linkText: impactItem.cta.label,
                        linkedContentUrl: url,
                        unitName: sys.id,
                        componentId: impactItem.sys.id,
                        unitLocation: 'inline',
                        positionInUnit: index + 1,
                        parentComponentId: parentComponent?.sys.id,
                        positionInParentComponent: getPositionInParent(sys.id)
                      })
                    }}
                  >
                    {impactItem.cta.label}
                    <SVGarrowRight
                      width={20}
                      height={20}
                      className='fill-static-scarletTint vertical-align-sm-middle ml-sm-2'
                    />
                  </Link>
                </TrackImpression>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ComponentHomepageImpact
