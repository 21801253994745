import { gql } from '@apollo/client'
import { entryFragment, IEntry } from './Entry.type'
import { genericContentFragment, IGenericContent } from './GenericContent.type'

export interface IPageHelperSharedComponents extends IEntry {
  components: (IEntry | IGenericContent)[]
}

export const pageHelperSharedComponentsFragment = gql`
  fragment pageHelperSharedComponentsFragment on Query {
    pageHelperSharedComponentsCollection(
      where: { slug: $pageHelperSlug }
      limit: 1
      locale: $locale
      preview: $preview
    ) {
      items {
        ...entryFragment
        componentsCollection(limit: 15) {
          items {
            ...entryFragment
            ...genericContentFragment
          }
        }
      }
    }
  }
  ${entryFragment}
  ${genericContentFragment}
`

export const pageHelperSharedComponentsQuery = gql`
  query PageHelperSharedComponents(
    $preview: Boolean!
    $locale: String!
    $pageHelperSlug: String = "generic-layout"
    ) {
    ...pageHelperSharedComponentsFragment
  }
  ${pageHelperSharedComponentsFragment}
`
