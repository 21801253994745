import { Image } from '@/journey/Image'
import { SVGborderTriangleDown } from '@/journey/Svgs/borders'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { RichText } from '@fractal/primitives/atoms/RichText'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import styles from './ComponentCampaignHeader.module.scss'
import { IComponentCampaignHeader } from './ComponentCampaignHeader.type'

export function ComponentCampaignHeader({
  sys,
  title,
  description,
  image,
  backgroundColor = 'ochreLite',
  hideJumpTo
}: IComponentCampaignHeader) {
  const { trackClick } = useContext(AnalyticsContext)
  const { headers } = useContext(PageContext)
  const { t } = useTranslation()
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const anchors = headers
    .filter((h) => h.level === 'h2')
    .filter((h, index) => index > 0)

  return (
    <div data-testid='header-campaign-section'>
      <TrackImpression
        unitName={sys.id}
        unitLocation='header'
        componentId={sys.id}
      />
      <div
        className={`bg-background-low-${backgroundColor} pb-sm-5 pb-md-6 o-sm-hidden`}
      >
        <div className='container'>
          {!!image && (
            <div className='d-sm-flex flex-sm-justify-center'>
              <Image
                alt=''
                src={image.url}
                priority
                sizes='157px'
                width={157}
                height={89}
              />
            </div>
          )}
          <div className='grid'>
            <h1
              id='target-skip-to-content'
              className='mt-sm-5 f-sm-8 f-md-11 lh-condensed text-center grid-start-md-3 grid-col-sm-12 grid-col-md-8'
            >
              {title}
            </h1>
          </div>
          {!!description && (
            <div className='grid mt-sm-4'>
              <div className='f-sm-4 f-md-5 grid-start-md-4 grid-col-sm-12 grid-col-md-6'>
                <RichText richTextContent={description.json} richTextLinks={description.links} unstyled />
              </div>
            </div>
          )}
        </div>

        {!hideJumpTo && (
          <div className='container mt-sm-5 d-md-flex flex-md-justify-center d-sm-block'>
            <div className=''>
              <span className='d-sm-block f-sm-3 f-md-2 text-italic mb-sm-3 mb-md-4 text-md-center'>
                {t('jump-to-section')}
                :
              </span>
              <div>
                <ul
                  className={`mx-sm-n5 mx-md-0 my-sm-0 px-sm-5 px-md-0 list-reset d-sm-flex o-sm-auto
                    ${styles.listContainer}
                  `}
                >
                  {anchors.map((anchor, index) => (
                    <li className='mr-sm-2' key={anchor.id}>
                      <a
                        className='f-sm-2 px-sm-4 Btn Btn--primary Btn--small no-underline'
                        href={`#${anchor.id}`}
                        onClick={() => {
                          trackClick({
                            clickType: 'button',
                            buttonName: 'section_anchor',
                            buttonText: anchor.text,
                            linkedContentUrl: anchor.id,
                            unitName: 'jumplink',
                            unitLocation: 'header',
                            componentId: sys.id,
                            positionInUnit: index + 1,
                            parentComponentId: parentComponent?.sys.id,
                            positionInParentComponent: getPositionInParent(sys.id)
                          })
                        }}
                      >
                        <TrackImpression
                          key={anchor.id}
                          linkedContentUrl={anchor.id}
                          unitName='jumplink'
                          unitLocation='header'
                          componentId={sys.id}
                          positionInUnit={index + 1}
                          buttonName='section_anchor'
                          buttonText={anchor.text}
                        />
                        {anchor.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <SVGborderTriangleDown
        pathfillColor={`background-low-${backgroundColor}`}
        id='headerBorder'
      />
    </div>
  )
}

export default ComponentCampaignHeader
