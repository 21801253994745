import { Image } from '@/journey/Image'
import SVGborderTriangleDown from '@/journey/Svgs/borders/triangleDown'
import { Tag } from '@/journey/Tag'
import SVGaltWave from '@fractal/primitives/SVGs/waves/altWave'
import RichText from '@fractal/primitives/atoms/RichText'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import ContentfulCta from '../../shared/components/ContentfulCta'
import ContentfulIcon from '../../shared/components/ContentfulIcon'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentPromoBanner } from './ComponentPromoBanner.type'

const defaultColor = 'peachTint'

export default function ComponentPromoBanner({
  title,
  image,
  description,
  ctaLabel,
  ctaLink,
  ctaIcon,
  rubricIcon,
  rubricLabel,
  rubricLink,
  backgroundColor,
  sys
}: IComponentPromoBanner) {
  const { pageLayout } = useContext(PageContext)
  const { getPositionInParent, entries } = usePageComponentsContext()
  const isLast = getPositionInParent(sys.id) === entries.length

  return (
    <div
      className={
        pageLayout.columnWidthLarge
          ? sharedStyles.fullWidthLargeGrid10
          : sharedStyles.fullWidthLargeGrid12
      }
    >
      <div
        data-testid='promo-banner'
        className={`${sharedStyles.sectionMargin}`}
      >
        <SVGaltWave
          className={`fill-background-low-${backgroundColor || defaultColor}`}
        />
        <TrackImpression
          as='fragment'
          unitName={sys.id}
          componentId={sys.id}
          unitLocation='inline'
        />
        <div
          className={`bg-background-low-${backgroundColor || defaultColor
          } pt-sm-4 pb-sm-6 pt-md-5 pb-md-6`}
        >
          <div className='container grid'>
            <div className={`grid-col-sm-10 grid-start-sm-2 grid-col-md-6 grid-start-md-4 grid-col-lg-4 
              grid-start-lg-2 grid-align-self-lg-center`}
            >
              <Image
                alt=''
                width={400}
                height={400}
                src={image.url}
                className='w-sm-full h-sm-auto'
              />
            </div>
            <div className={`grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-col-lg-5 grid-start-lg-7 
              grid-align-self-lg-center`}
            >
              {rubricLabel && (
                <div className='mb-sm-3'>
                  <Tag href={rubricLink}>
                    {rubricIcon && (
                      <ContentfulIcon
                        name={rubricIcon}
                        className='fill-svg w-sm-3 h-sm-3 mr-sm-1'
                      />
                    )}
                    {rubricLabel}
                  </Tag>
                </div>
              )}
              <h2 className='f-sm-6 f-md-7 mb-sm-3 lh-condensed'>{title}</h2>
              <div className='f-sm-3 f-md-5 mb-sm-4'>
                <RichText richTextContent={description.json} richTextLinks={description.links} unstyled />
              </div>
              <ContentfulCta
                ctaLabel={ctaLabel}
                ctaLink={ctaLink}
                ctaIcon={ctaIcon}
                snowplowEvents={{
                  unitName: sys.id
                }}
                data-testid='promobanner-cta'
              />
            </div>
          </div>
        </div>
        {!isLast && (
          <SVGborderTriangleDown
            pathfillColor={`background-low-${backgroundColor || defaultColor}`}
            id={`promoBannerBorder-${sys.id}`}
          />
        )}
      </div>
    </div>
  )
}
