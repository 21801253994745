import VideoPlayer from 'components/articles/VideoPlayer'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import { IVideoBlockLink } from 'components/dynamic-page/shared/types/VideoBlockLink.type'
import React, { useContext } from 'react'
import sharedStyles from '../../../shared/styles/DynamicComponents.module.scss'

export interface ILargeVideo extends IEntry {
  content: IVideoBlockLink
}

export default function LargeVideo({ content, sys }: ILargeVideo) {
  const { pageLayout } = useContext(PageContext)
  if (!content?.videoUrl) return null

  return (
    <div
      className='mb-sm-5 mb-md-6 mt-sm-3 mt-md-6 no-print'
      data-testid='large-hero'
    >
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={content.videoUrl}
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
        unitVariation='video_content'
      />
      <VideoPlayer
        url={content.videoUrl}
        videoId={content.sys.id}
        className={`${pageLayout.columnWidthLarge === 10
          ? 'grid-col-sm-10 grid-start-lg-2'
          : 'grid-col-sm-12 grid-start-lg-3'
        } grid-col-lg-8 mx-sm-n5 mx-md-0 rounded-md-1 o-sm-hidden`}
        containerClassName={
          pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : undefined
        }
        applyAspectRatio
      />
    </div>
  )
}
