import { Link } from '@/journey/Link'
import capitalize from 'lodash/capitalize'
import styled, { css } from 'styled-components'

type IStyledArrowLink = {
  textsize?: 'default' | 'large' | 'small'
  textcolor?: 'inherit' | 'default' | 'none'
  iconAlign?: 'right' | 'left'
}

export const StyleArrowLink = styled(Link) <IStyledArrowLink>`
  font-size: ${({ theme, textsize }) =>
    theme[`componentArrowLinkSize${capitalize(textsize)}FontSize`]
      ? theme[`componentArrowLinkSize${capitalize(textsize)}FontSize`]
      : theme.componentArrowLinkSizeDefaultFontSize}px;
  color: ${props =>
    props.textcolor === 'inherit'
      ? 'inherit'
      : props.textcolor === 'none'
        ? undefined
        : props.theme.componentArrowLinkColor};
  cursor: ${props => props.theme.componentArrowLinkCursor};
  &:focus,
  &:hover {
    text-decoration: ${props => props.theme.componentArrowLinkTextDecoration};
  }
  &:focus {
    color: ${props => props.theme.colorForegroundDarkblue};
    background-color: ${props =>
    props.theme.componentArrowLinkFocusBackgroundColor};
  }

  svg {
    vertical-align: middle;
  }

  ${({ theme, iconAlign }) =>
    iconAlign === 'right' &&
    css`
      &:hover ${StyleIconSVG} {
        transform: translateX(${theme.sizeSpacer2}px);
      }
    `}

  ${({ theme, iconAlign }) =>
    iconAlign === 'left' &&
    css`
      &:hover ${StyleIconSVG} {
        transform: translateX(${theme.sizeSpacerN2}px);
      }
    `}
`

export const StyleIconSVG = styled.span`
  color: ${props => props.theme.componentArrowLinkSvgColor};
  fill: ${props => props.theme.componentArrowLinkSvgColor};
  margin-left: ${props => props.theme.componentArrowLinkSvgMarginLeft}px;
  margin-right: ${props => props.theme.componentArrowLinkSvgMarginRight}px;
  vertical-align: ${props => props.theme.componentArrowLinkSvgVerticalAlign};
  transition: transform 0.2s;
`
