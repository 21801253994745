import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { SVGborderTriangleDown } from '@/journey/Svgs/borders'
import SVGemail from '@/journey/Svgs/icons/email'
import SVGloading from '@/journey/Svgs/icons/loading'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import SVGaltWave from '@fractal/primitives/SVGs/waves/altWave'
import { RichText } from '@fractal/primitives/atoms/RichText'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'
import { Input } from '@fractal/primitives/atoms/forms/Input'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import useTranslation from 'app/i18n/client'
import axios from 'axios'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useRef,
  useState
} from 'react'
import { Trans } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentNewsletter } from './ComponentNewsletter.type'

type FormState = 'IDLE' | 'SAVING' | 'SUCCESS' | 'ERROR'

export default function ComponentNewsletter({
  sys,
  ...props
}: IComponentNewsletter) {
  const { t } = useTranslation()
  const uniqueId = useRef(uuidV4())
  const languageContext = useContext(LanguageContext)
  const { trackSpEngagement } = useContext(AnalyticsContext)
  const [email, setEmail] = useState('')
  const [formState, setFormState] = useState<FormState>('IDLE')
  const [errorMessage, setErrorMessage] = useState('')
  const { pageLayout } = useContext(PageContext)
  const { parent, getPositionInParent, entries } = usePageComponentsContext()
  const isLast = getPositionInParent(sys.id) === entries.length

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setFormState('IDLE')
    setErrorMessage('')
  }

  const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

    trackSpEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: !!isValidEmail,
      unitName: sys.id,
      unitLocation: 'footer',
      componentId: sys.id,
      parentComponentId: parent?.sys.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })

    if (!isValidEmail) {
      setErrorMessage(t('invalid-email'))
      return
    }

    setFormState('SAVING')
    const locale = LanguagePathMap.get(languageContext.language) || 'en'

    const isEducators = languageContext.slug.indexOf('/educators') > -1
    const selectedRoles: any = Array.from(
      new Set([].concat(props.tags as any).concat(isEducators ? ['educators'] as any : []))
    ).filter((a) => !!a)

    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${locale}/subscribe`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          email,
          tag: props.tags,
          web_user_selected_roles: selectedRoles,
          join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${languageContext.slug}`
        }
      })
      setEmail('')
      setFormState('SUCCESS')
    } catch {
      setErrorMessage(t('something-went-wrong'))
      setFormState('ERROR')
    }
  }

  return (
    <div
      data-testid='component-newsletter'
      className={`${pageLayout.columnWidthLarge === 10
        ? sharedStyles.fullWidthLargeGrid10
        : sharedStyles.fullWidthLargeGrid12} no-print`}
    >
      <SVGaltWave className='fill-background-low-ochreLite' />
      <TrackImpression
        as='span'
        componentId={sys.id}
        unitName={sys.id}
        unitLocation='inline'
      />
      <div className='bg-background-low-ochreLite pt-sm-4 pb-sm-6 pt-md-5 pb-md-6'>
        <div className='container grid'>
          <div className={`grid-col-sm-10 grid-start-sm-2 grid-col-md-6 grid-start-md-4 grid-col-lg-4
            grid-start-lg-2 grid-align-self-lg-center`}
          >
            <Image
              alt=''
              width={400}
              height={400}
              src={props.image.url}
              resizeAspectRatio={4}
              className='w-sm-full h-sm-auto'
            />
          </div>
          <div className={`grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-col-lg-5
            grid-start-lg-7 grid-align-self-lg-center`}
          >
            {formState !== 'SUCCESS' && (
              <>
                <h2 className='f-sm-6 f-md-7 mb-sm-3 lh-condensed'>
                  {props.title}
                </h2>
                <div className='f-sm-3 f-md-5 mb-sm-4'>
                  <RichText richTextContent={props.description.json} richTextLinks={props.description.links} unstyled />
                </div>

                <form onSubmit={submitForm} noValidate>
                  <FormControl>
                    <Label
                      htmlFor={`newsletter_email_input_${uniqueId.current}`}
                      required
                      size='default'
                      weight='default'
                      marginSize='default'
                    >
                      {t('email-address')}
                    </Label>
                    <Input
                      id={`newsletter_email_input_${uniqueId.current}`}
                      value={email}
                      required
                      onChange={handleEmailChange}
                      data-testid='newsletter-signup-input'
                      controlSize='default'
                      noBorder
                      disabled={formState === 'SAVING'}
                    />
                    <FormError data-testid='newsletter-error-message'>{errorMessage}</FormError>
                  </FormControl>

                  <div className='d-sm-flex flex-sm-align-center mb-sm-5'>
                    <button
                      type='submit'
                      disabled={formState === 'SAVING'}
                      className='Btn Btn--primary no-underline'
                      aria-describedby={`emailTerms_${uniqueId.current}`}
                      data-testid='newsletter-signup-button'
                    >
                      <SVGemail className='fill-svg mr-sm-2' />
                      {t('subscribe')}
                    </button>
                    {formState === 'SAVING' && (
                      <SVGloading className='fill-svg ml-sm-4' />
                    )}
                  </div>

                  <div id={`emailTerms_${uniqueId.current}`} className='f-sm-2'>
                    <Trans t={t} i18nKey='newsletter-disclaimer'>
                      Review our
                      <Link href='/privacy-policy'>privacy policy</Link>
                      . You
                      can opt out of emails at any time by sending a request to
                      <a
                        className='primary-link'
                        href='mailto:info@understood.org'
                      >
                        info@understood.org
                      </a>
                      .
                    </Trans>
                  </div>
                </form>
              </>
            )}
            {formState === 'SUCCESS' && (
              <div
                className={`text-foreground-midblue f-sm-6 d-sm-flex flex-sm-align-center
                  flex-sm-justify-center px-sm-6 py-sm-7 text-center`}
                role='status'
                data-testid='newsletter-success-banner'
              >
                {t('newsletter-success')}
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLast && (
        <SVGborderTriangleDown
          pathfillColor='background-low-ochreLite'
          id={`promoBannerBorder-${sys.id}`}
        />
      )}
    </div>
  )
}
