import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGpodcast = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='podcast-svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30 10V22C30 24.7614 27.7614 27 25 27C22.2385 27 20 24.7614 20 22V10C20 7.23858 22.2385 5 25 5C27.7614 5 30 7.23858 30 10ZM15 10C15 4.47715 19.4771 0 25 0C30.5228 0 35 4.47715 35 10V22C35 27.5228 30.5228 32 25 32C19.4771 32 15 27.5228 15 22V10ZM5.15409 24.495C4.98358 23.1249 6.11926 22 7.49997 22C8.88068 22 9.97813 23.1277 10.2056 24.4895C11.3915 31.589 17.5638 37 25 37C32.4361 37 38.6084 31.589 39.7943 24.4895C40.0218 23.1277 41.1193 22 42.5 22C43.8807 22 45.0164 23.1249 44.8459 24.495C43.6991 33.7102 36.2832 40.9796 27 41.9012V45H32.5C33.8807 45 35 46.1193 35 47.5C35 48.8807 33.8807 50 32.5 50H17.5C16.1193 50 15 48.8807 15 47.5C15 46.1193 16.1193 45 17.5 45H22V41.7765C13.1884 40.451 6.25938 33.3768 5.15409 24.495Z'
      />
    </svg>
  )
}

export default SVGpodcast
