import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { useContext, useEffect } from 'react'
import { ArticleSpotlight } from './ArticleSpotlight'
import { IComponentSpotlight } from './ComponentSpotlight.type'
import { PodcastEpisodeSpotlight } from './PodcastEpisodeSpotlight'
import { VideoSpotlight } from './VideoSpotlight'

const componentMap = {
  PodcastEpisode: PodcastEpisodeSpotlight,
  VideoBlock: VideoSpotlight,
  Article: ArticleSpotlight
}

export default function ComponentSpotlight({
  title,
  resource,
  contentType,
  filterByCollection,
  externalFilter,
  sys
}: IComponentSpotlight) {
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)

  const handpickedContentList = {
    items: [resource].filter((content) => !!content)
  }

  const content = getData(sys.id).slice(0, 1)[0]

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit: 1,
      contentType,
      filterByCollection,
      contentListCollection: handpickedContentList,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [sys.id, contentType, filterByCollection, externalFilter, resource])

  const Component = componentMap[content?.typename]

  if (!Component) return null

  return (
    <Component
      content={content}
      title={title}
      isSectionLoading={isSectionLoading(sys.id)}
      unitName={sys.id}
    />
  )
}
