import VideoPlayer from 'components/articles/VideoPlayer'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IVideoBlockLink } from 'components/dynamic-page/shared/types/VideoBlockLink.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'

interface IVideoSpotlightProps {
  content: IVideoBlockLink
  positionInUnit: number
  spotlight?: boolean
  unitName: string
}

function VideoSpotlight({
  content,
  spotlight,
  positionInUnit,
  unitName
}: IVideoSpotlightProps) {
  if (!spotlight) return null

  const url = getContentUrl(content)

  return (
    <>
      <TrackImpression
        key={content.sys.id}
        linkedContentId={content.sys.id}
        linkedContentUrl={url}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={positionInUnit}
      />
      {spotlight && (
        <div className='p-sm-relative o-sm-hidden rounded-lg-1 mb-sm-4 mx-sm-n5 mx-md-0'>
          <VideoPlayer
            url={content.videoUrl}
            videoId={content.sys.id}
            className='grid-col-sm-12'
            fullSizeContainer
            applyAspectRatio
          />
        </div>
      )}

      <div className='d-sm-flex flex-sm-align-center'>
        <div className='flex-sm mr-sm-4 text-bold'>
          <h3 className='f-sm-6 f-lg-7'>{content.internalTitle}</h3>
        </div>
      </div>
    </>
  )
}

export default VideoSpotlight
