import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import TrackImpression from '@fractal/tracking/TrackImpression'
import useTranslation from 'app/i18n/client'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { useContext } from 'react'
import ReactModal from 'react-modal'
import { useMedia } from 'the-platform'
import { EmailForm } from './EmailForm'

const customStylesMd = {
  content: {
    padding: '52px',
    borderRadius: '4px',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    width: 500,
    height: 430,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 100 }
}

const customStylesSm = {
  content: {
    left: 0,
    right: 0,
    padding: '52px 30px',
    borderRadius: '0',
    height: 415,
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  overlay: { zIndex: 100 }
}

export interface ISubscribeModal {
  isModalOpened: boolean
  onClose: () => void
  onSubmit: () => void
  unitVariation: string
  tags: string[]
  copy: {
    bold?: string
    regular?: string
    noText?: string
  }
}

function SubscribeModal({
  isModalOpened,
  onClose,
  onSubmit,
  unitVariation,
  tags,
  copy
}: ISubscribeModal) {
  const isMd = useMedia({ minWidth: 768 })
  const { trackEngagement } = useContext(AnalyticsContext)
  const { setIsEmailProvided } = useContext(PageContext)
  const { t } = useTranslation()

  const handleOnSubmit = ({ email }) => {
    setIsEmailProvided(true)
    trackEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: true,
      unitName: 'account_gate',
      unitLocation: 'modal',
      buttonName: 'subscribe',
      unitVariation
    })
    onSubmit()
  }

  return (
    <ReactModal
      isOpen={isModalOpened}
      onRequestClose={onClose}
      style={isMd ? customStylesMd : customStylesSm}
      preventScroll
    >
      <button
        aria-label={t('close-email-subscription-window')}
        data-testid='close-modal'
        onClick={() => {
          trackEngagement({
            eventType: 'dismiss',
            unitName: 'account_gate',
            buttonName: 'skip',
            unitLocation: 'modal',
            unitVariation
          })
          onClose()
        }}
        className='Btn-link p-sm-absolute right-sm-4 top-sm-4 right-md-5 top-md-5'
      >
        <SVGclose className='vicon' />
      </button>
      <div className=''>
        <TrackImpression
          unitName='account_gate'
          buttonName={null}
          unitLocation='modal'
          unitVariation={unitVariation}
        />
        <h1 className='f-sm-5 mb-sm-3 lh-condensed text-regular'>
          {copy.bold && (
            <strong>
              {copy.bold}
              {' '}
            </strong>
          )}
          {copy.regular ?? ''}
        </h1>
        <EmailForm
          onSubmit={(email) => {
            handleOnSubmit(email)
            onClose()
          }}
          tags={tags}
        />
        {copy.noText && (
          <button
            className='mt-sm-4 text-foreground-midblue Btn-link text-underline f-sm-4'
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            {copy.noText}
          </button>
        )}
      </div>
    </ReactModal>
  )
}

export default SubscribeModal
