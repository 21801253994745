import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import SVGemail from '@fractal/primitives/SVGs/socials/email'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'
import { Input } from '@fractal/primitives/atoms/forms/Input'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import useTranslation from 'app/i18n/client'
import axios from 'axios'
import { useContext, useState } from 'react'

export function EmailForm({ onSubmit, tags }: any) {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [error, toggleEmailError] = useState(false)
  const { language, slug } = useContext(LanguageContext)
  const locale = LanguagePathMap.get(language) || 'en'

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    toggleEmailError(false)
  }

  const submitForm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    if (!isValidEmail) {
      toggleEmailError(true)
      return
    }

    await axios({
      url: `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${locale}/subscribe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email,
        tag: tags,
        web_user_selected_roles: tags,
        join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${slug}`
      }
    }).then(() => {
      onSubmit?.(email)
    })
  }

  return (
    <div className='grid-col-sm-12'>
      <div className='grid grid-gap-none-sm '>
        <div className='grid-col-sm-12'>
          <form onSubmit={submitForm}>
            <FormControl>
              <Label
                htmlFor='email'
                required
                size='default'
                weight='default'
                marginSize='default'
              >
                {t('email-address')}
              </Label>
              <Input
                id='email'
                value={email}
                required
                onChange={handleEmailChange}
                controlSize='default'
                noBorder={false}
              />
              <FormError>{error ? t('enter-a-valid-email') : ''}</FormError>
            </FormControl>

            <div>
              <button type='button' className='Btn Btn--shadow Btn--responsive'>
                <SVGemail className='vicon' width={20} height={20} />
                {t('subscribe')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmailForm
