import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGdropdown = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.79961 7.8031L4.19961 6.3999L10.1896 12.4115L16.1992 6.3999L17.5996 7.8031L11.6104 13.8139C10.8348 14.5939 9.57001 14.5955 8.79121 13.8175L2.79961 7.8031Z'
      />
    </svg>
  )
}

export default SVGdropdown
