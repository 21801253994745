import { Avatar } from '@/journey/Avatar'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGwunder from '@fractal/primitives/SVGs/logos/wunder'
import useTranslation from 'app/i18n/client'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import { isIOS } from 'react-device-detect'
import { Trans } from 'react-i18next'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { IComponentWunderExpert } from './ComponentWunderExpert.type'

const wunderAppLink = 'https://apps.apple.com/app/apple-store/id1552485748?pt=122591629&ct=traditional_feed&mt=8'

export default function ComponentWunderExpert({
  sys,
  person
}: IComponentWunderExpert) {
  const { trackClick } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const { pageLayout } = useContext(PageContext)

  return (
    <div className={`${pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'} my-sm-6`}>
      <div
        className={
          pageLayout.columnWidthLarge === 10
            ? 'grid-col-sm-10 grid-start-lg-3 grid-col-lg-6'
            : 'grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'
        }
      >
        <div
          data-testid='wunder-expert-banner'
          className='grid grid-gap-none py-sm-5 py-md-6 border-sm-top border-sm-bottom border-foreground-ochre'
        >
          <div className='grid-col-sm-12 grid-col-md-4'>
            <div className='w-sm-7 h-sm-7 mx-sm-auto mb-sm-5 mb-md-0'>
              <Avatar
                src={person.headshot?.url}
                alt=''
                sizes='160px'
                expert={person.role === 'Expert'}
              />
            </div>
          </div>
          <div className='grid-col-sm-12 grid-col-md-8 text-sm-center text-md-left pl-sm-4 pl-md-0 pr-sm-4'>
            <p className='f-sm-6 f-md-7 text-bold mb-sm-2'>
              <TrackImpression
                unitName={sys.id}
                componentId={sys.id}
                unitLocation='inline'
              />
              <Trans
                t={t}
                i18nKey='expert-on-wunder'
                values={{ name: person.firstName }}
                components={{ wunderLogo: <SVGwunder fill='#EF573D' width={32} height={20} /> }}
              />
            </p>
            <p className='f-sm-3 f-md-5'>
              <Trans t={t} i18nKey='expert-download-wunder'>
                <Link
                  href={isIOS ? wunderAppLink : '/wunder'}
                  className='primary-link'
                  onClick={() => {
                    if (isIOS) {
                      trackClick({
                        clickType: 'button',
                        componentId: sys.id,
                        unitName: sys.id,
                        isExternal: true,
                        buttonName: 'download_app',
                        unitLocation: 'inline'
                      })
                    } else {
                      localStorage.setItem('wunder_smart_cta', 'traditional_feed')
                      trackClick({
                        clickType: 'button',
                        componentId: sys.id,
                        unitName: sys.id,
                        buttonName: 'wunder',
                        unitLocation: 'inline'
                      })
                    }
                  }}
                >
                  Download our community app
                </Link>
                and get advice from this expert for free.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
