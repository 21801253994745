import DynamicComponentRenderer from 'components/dynamic-page/DynamicComponentRenderer'
import { PageComponentsProvider } from 'components/dynamic-page/providers/PageComponentsContext'
import SectionHeader from 'components/dynamic-page/shared/components/SectionHeader'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import { ILayoutComponentSection } from './LayoutComponentSection.type'

export default function LayoutComponentSection(props: ILayoutComponentSection) {
  const { title, componentsCollection, ...data } = props
  return (
    <div
      className={`${sharedStyles.sectionMargin}`}
      data-testid='layout-component-section'
    >
      <TrackImpression
        unitName={data.sys.id}
        componentId={data.sys.id}
        unitLocation='inline'
        childComponentId={componentsCollection.items.map((c) => c.sys.id)}
      />
      <SectionHeader title={title} />
      <div>
        <PageComponentsProvider parent={data} entries={componentsCollection.items}>
          <DynamicComponentRenderer
            block={componentsCollection.items}
            parent={props}
          />
        </PageComponentsProvider>
      </div>
    </div>
  )
}
