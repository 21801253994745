import { Avatar } from '@/journey/Avatar'

interface IAvatarRowProps extends React.AnchorHTMLAttributes<HTMLDivElement> {
  srcs: string[]
  borderColorVar?: string
}

const backgroundColor = [
  'bg-static-scarletTint',
  'bg-static-midblueLite',
  'bg-static-ochre'
]

function AvatarRow({
  srcs,
  borderColorVar = '--background-low-peachTint',
  ...props
}: IAvatarRowProps) {
  return (
    <div
      {...props}
      className={`z-index-1 d-sm-flex ml-sm-n1 ${props.className || ''}`}
    >
      {srcs.map((src, index) => (
        <div
          key={src}
          style={{
            borderColor: `var(${borderColorVar})`,
            width: 56,
            height: 56,
            zIndex: 20 - index
          }}
          className={`circle-sm border-sm-4 ${index !== 0 ? 'ml-sm-n3' : ''}`}
        >
          <Avatar
            src={src}
            className={backgroundColor[index]}
            priority
            sizes='52px'
          />
        </div>
      ))}
    </div>
  )
}

export default AvatarRow
